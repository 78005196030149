import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {useWindowHeight} from '@react-hook/window-size';

import './ProductSelector.scss';
import constants from "./constants";

const ProductSelector = ({countryInsights, configs, onInfoClick}) => {
    const windowHeight = useWindowHeight();
    const tallScreen = windowHeight > constants.tallScreenMinHeight;
    const smallScreen = windowHeight <= constants.mediumScreenMinHeight;

    const allRegions = [
        { label: "Latin America and the Caribbean", value: "rbp" },
    ]
    const extractLabel= (insights) =>  insights.map(
        (i) => ({ 'label': i.country, 'value': i.iso3.toLowerCase()}))

    const optionsStaticInsight = [
        { label: 'INSIGHTS AND KEY TRENDS', value:'', isDisabled: true },
        { label: 'Global', value:'global' },
        {
            label: "Regional",
            options: configs.region === 'global'? allRegions : allRegions.filter(x => x.value === configs.region)
        },
        {   label: "Country",
            options: configs.region  === 'global' ? extractLabel(countryInsights)
                : extractLabel(countryInsights)
        },
    ];

    const optionsStatic = [...optionsStaticInsight]

    const onChange = (e) => {
        let url = `https://static.hungermapdata.org/insight-reports/latest/${e.value}-summary.pdf`;
        // let url = countryInsights.find(report => report.iso3.toLowerCase() === `${e.value}`).url

        window.open(url, '_blank').focus();
    }

    const fontSize = tallScreen ? '10pt' : (smallScreen ? '7pt' : '8pt');
    const height = tallScreen ? 30 : (smallScreen ? 20 : 25);

    const customStyle = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'RGBA(0, 0, 0, 0.6)',
            fontSize: fontSize,
            borderColor: `RGBA(255, 255, 255, ${state.isFocused ? '0.65' : '0.5'})`,
            boxShadow: state.isFocused ? 0 : 0,
            borederRadius: 2,
            height: height,
            minHeight: height,
            '&:hover': {
                borderColor: 'RGBA(255, 255, 255, 0.65)',
            }
        }),
        input: (provided, state) => ({
            ...provided,
            color: `RGBA(255, 255, 255, ${state.isFocused ? 0.65 : 0.5})`,
        }),
        singleValue: (provided) => {
            const opacity = 0.5;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        },
        placeholder: (provided) => ({
            ...provided,
            top: tallScreen ? 12 : (smallScreen ? 8 : 10),
            paddingBottom: 6
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: height
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: 'RGBA(255, 255, 255, 0.5)',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: `RGBA(255, 255, 255, ${state.isFocused ? 0.65 : 0.5})`,
            width: height * 1.3,
            height: height,
            paddingTop: tallScreen ? '5px' : (smallScreen ? '0' : '3px'),
            '&:hover': {
                color: 'RGBA(255, 255, 255, 0.65)',
            }
        }),
        menu: (provided, state) => ({
            ...provided,
            fontSize: fontSize,
            color: 'rgba(255, 255, 255, 0.8)',
            backgroundColor: 'RGBA(0, 0, 0, 0.8)',
            zIndex: 1200
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ?  state.isDisabled ? 'RGBA(0,0,0,0)' : '#018FA9' : 'RGBA(0, 0, 0, 0)',
            color: state.isDisabled ? '#018FA9' :'#fff',
            paddingLeft: state.isDisabled ? '5px' : '20px',
            fontSize: state.isDisabled ?  tallScreen ? '8pt' : (smallScreen ? '6pt' : '7pt'): fontSize
        })
    };


    return <div className={`productSelector `}>
        <div className={'container'}>
            <p className={'label'}>
                <span> Access PDF reports </span>
                <span className="infoIcon" onClick={() => onInfoClick('productsAccessSection')}>&nbsp;</span>
            </p>
            <Select options={optionsStatic} styles={customStyle} placeholder={'Download an analysis'} value={null}
                    onChange={onChange}/>
        </div>
    </div>
};

ProductSelector.propTypes = {
    active: PropTypes.bool,
    configs: PropTypes.object,
    countryInsights: PropTypes.array,
    onInfoClick: PropTypes.func
};
ProductSelector.defaultProps = {
    active: false,
    countryInsights: [],
    configs: {region:'global'},
    onInfoClick: () => {}
};

export default ProductSelector;