import React from "react";
import L from 'leaflet';

export default {
    tallScreenMinHeight: 930,
    mediumScreenMinHeight: 720
};

export const mapSettings ={
    global: {
        bounds: L.latLngBounds(L.latLng(66, -88), L.latLng(-60, 142)),
        header: 'Global',
        link: 'global',
        iso3s: []
    },
    rbb: {
        bounds: L.latLngBounds(L.latLng(44.5, 42.45), L.latLng(-31, 211.2)),
        header: 'Asia and the Pacific',
        link: 'asia-pacific',
        iso3s:['AFG','BGD','BTN','FJI','FSM','IDN','IND','KHM','KIR','LAO','LKA','MHL','MMR','NPL','NRU','PAK','PHL','PLW','PNG','PRK','SLB','TLS','TON','TUV','VUT','WSM']
    },
    rbc: {
        bounds: L.latLngBounds(L.latLng(52.3, -18.5), L.latLng(7.1, 92.8)),
        header: 'Middle East and Northern Africa',
        link: 'middle-east-northern-africa',
        iso3s:['ARM','DZA','EGY','IRN','IRQ','JOR','KGZ','LBN','LBY','PSE','SYR','TJK','TUN','TUR','YEM','UKR']
    },
    rbd: {
        bounds: L.latLngBounds(L.latLng(31.46, -37.6), L.latLng(-7.9, 46.75)),
        header: 'Western Africa',
        link: 'western-africa',
        iso3s:['BEN','BFA','CAF','CIV','CMR','GHA','GIN','GMB','GNB','LBR','MLI','MRT','NER','NGA','SEN','SLE','STP','TCD','TGO']
    },
    rbj: {
        bounds: L.latLngBounds(L.latLng(12.5, -24.6), L.latLng(-38.47, 86.73)),
        header: 'Southern Africa',
        link: 'southern-africa',
        iso3s:['AGO','COD','COG','LSO','MDG','MOZ','MWI','NAM','SWZ','TZA','ZMB','ZWE']
    },
    rbn: {
        bounds: L.latLngBounds(L.latLng(27.5, -6.6), L.latLng(-12.5, 77.8)),
        header: 'Eastern Africa',
        link: 'eastern-africa',
        iso3s:['BDI','DJI','ETH','KEN','RWA','SDN','SOM','SSD','UGA']
    },
    rbp: {
        bounds: L.latLngBounds(L.latLng(29.5, -136.4), L.latLng(-30, -8.5)),
        header: 'Latin America and the Caribbean',
        link: 'latin-america-caribbean',
        iso3s:['ABW','ATG','BHS','BLZ','BMU','BOL','BRB','COL','CUB','CYM','DOM','ECU','GTM','GUY','HND','HTI','JAM','KNA','LCA','NIC','PER','SLV','SUR','TTO','VCT']
    },
    rbpidb: {
        bounds: L.latLngBounds(L.latLng(40.5, -146.4), L.latLng(-30, 1.5)),
        header: 'Latin America and the Caribbean',
        link: 'latin-america-caribbean',
        iso3s:['ABW','ATG','BHS','BLZ','BMU','BOL','BRB','COL','CUB','CYM','DOM','ECU','GTM','GUY','HND','HTI','JAM','KNA','LCA','NIC','PER','SLV','SUR','TTO','VCT']
    },
}
