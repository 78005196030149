import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import {useWindowHeight} from '@react-hook/window-size';
import constants from './constants';

HighchartsMore(Highcharts);

const unnest = arr => [].concat.apply([], arr);

const numberFormat = new Intl.NumberFormat('en-US');

const SI_SYMBOLS = ["", "k", "M", "G"];
const getUnitScaleNumber = (number) => {
    if (number === 0) return number;
    const tier = Math.floor(Math.log10(Math.abs(number)) / 3);
    return {
        unit: SI_SYMBOLS[tier],
        scale: 10 ** (tier * 3)
    };
}

const LineChart = ({series, height, buffer, allowDecimalTicks, allowNegativeTicks, allowMargin, selfRound}) => {
    const windowHeight = useWindowHeight();
    const tallScreen = windowHeight > constants.tallScreenMinHeight;
    const smallScreen = windowHeight <= constants.mediumScreenMinHeight;

    const valuesLists = series.map(s => s.data.map(({y}) => y));
    const valuesLowLists = series.map(s => s.data.filter(d => d.yLow !== undefined).map(({yLow}) => yLow));
    const valuesHighLists = series.map(s => s.data.filter(d => d.yHigh !== undefined).map(({yHigh}) => yHigh));
    const values = unnest(unnest([valuesLists, valuesHighLists, valuesLowLists])).filter(v => v !== null);

    const maxY = Math.max(...values);
    const minY = Math.min(...values);
    let minGraphY, maxGraphY, ticks;
    const datesLists = series.map(s => s.data.map(({x}) => x));
    const dates = unnest(datesLists);
    dates.sort((d1, d2) => d1.getTime() - d2.getTime());
    const totalMillis = dates[dates.length - 1].getTime() - dates[0].getTime();

    if (minY === maxY) {
        minGraphY = minY - 10;
        maxGraphY = maxY + 10;
        ticks = [minGraphY, minY, maxGraphY];
    } else {
        minGraphY = minY - (maxY - minY) * buffer;
        maxGraphY = maxY + (maxY - minY) * buffer;
        let decimals = 0;
        if (allowDecimalTicks) {
            if (Math.abs(maxY - minY) < 100) decimals = 1;
            if (Math.abs(maxY - minY) < 10) decimals = 2;
        }
        const roundingFactor = Math.pow(10, decimals);
        ticks = [
            Math.round(minGraphY*roundingFactor)/roundingFactor,
            Math.round((minGraphY + (maxGraphY-minGraphY)/3)*roundingFactor)/roundingFactor,
            Math.round((minGraphY + (maxGraphY-minGraphY)/3*2)*roundingFactor)/roundingFactor,
            Math.round(maxGraphY*roundingFactor)/roundingFactor
        ];
        if (!allowNegativeTicks && minGraphY < 0) {
            ticks = [0, ...ticks];
        }
        ticks.sort((a, b) => a - b);
    }

    const hasUnit = series.filter(s => s.unit !== undefined).length > 0;
    let unit,selfRoundScale;
    if(selfRound) {
        const maxUnitNumber = Math.max(Math.abs(minGraphY),Math.abs(maxGraphY))
        const unitScale = getUnitScaleNumber(maxUnitNumber);
        unit = unitScale.unit;
        selfRoundScale = unitScale.scale;
    } else {
        unit = (hasUnit ? series.filter(s => s.unit !== undefined).map(({unit}) => unit)[0] : null);
    }

    const hasLegend = series.filter(s => s.name !== undefined).length > 0;

    const fontSize = tallScreen ? '7pt' : (smallScreen ? '5pt' : '6pt');

    const chartSeries = unnest(series.map((s, idx) => {
        const seriesData = [{
            id: `main${idx}`,
            name: s.name, data: [], color: 'transparent',
            marker: {enabled: true, symbol: 'square', height: 20, width: 20, fillColor: s.color},
            type: 'spline',
            states: {hover: {enabled: false}}
        }, {
            id: `data${idx}`,
            linkedTo: `main${idx}`,
            name: `data${idx}`,
            data: s.data
                .map(({x, y}) => ({x: x.getTime(), y})),
            color: s.color,
            marker: {enabled: false},
            type: 'spline',
            states: {hover: {enabled: false}}
        }];
        const hasErrorBars = s.data.filter(d => d.yLow !== undefined && d.yHigh !== undefined).length > 1;
        if (hasErrorBars) {
            seriesData.push({
                linkedTo: `data${idx}`,
                name: `error${idx}`,
                data: s.data
                    .filter(d => d.yLow !== undefined && d.yHigh !== undefined)
                    .sort((a, b) => (a.x === b.x ? 0 : (a.x < b.x ? -1 : 1)))
                    .map(({x, yLow, yHigh}) => ({x: x.getTime(), low: yLow, high: yHigh})),
                color: s.color,
                marker: {enabled: false},
                type: 'arearange',
                fillOpacity: 0.3,
                lineWidth: 0,
                states: {hover: {enabled: false}}
            });
        }
        return seriesData;
    }));

    return <HighchartsReact
        highcharts={Highcharts}
        options={{
            title: {text: undefined},
            series: chartSeries,
            chart: {
                backgroundColor: 'transparent',
                height: height,
                width: tallScreen ? 300 : smallScreen ? 230 : 250,
                margin: allowMargin ? [hasUnit ? 30 : 15, undefined, hasUnit ? 30: 20, undefined] : undefined,
                marginTop: allowMargin ? undefined: 20
            },
            credits: {
                enabled: false
            },
            yAxis: {
                lineColor: '#fff',
                lineWidth: 1,
                title: {
                    text: unit,
                    rotation: 0,
                    style: {
                        color: '#fff',
                        fontSize: fontSize,
                        top: 20
                    },
                    margin: allowMargin ? 0: 15,
                    y: - (height / 3) - 5,
                    x: 10
                },
                gridLineWidth: 1,
                gridLineDashStyle: 'dot',
                gridLineColor: 'rgba(255, 255, 255, 0.1)',
                labels: {
                    enabled: true,
                    style: {
                        color: '#fff',
                        fontSize: fontSize,
                        width: 'auto'
                    },
                    x: -5,
                    useHTML: true,
                    formatter: function () {
                        if (!allowNegativeTicks && this.value < 0) return '';
                        if (selfRound) {
                            return numberFormat.format(Math.round((this.value / selfRoundScale) * 100) / 100);
                        }
                        return numberFormat.format(Math.round(this.value * 100) / 100);

                    }
                },
                max: maxGraphY,
                min: minGraphY,
                tickPositions: ticks
            },
            xAxis: {
                tickWidth: 0,
                labels: {
                    style: {
                        color: '#fff',
                        fontSize: fontSize
                    },
                    y: 13
                },
                type: 'datetime',
                dateTimeLabelFormats: {day: '%b %d', week: '%b %d', month: '%m-%y', year: '%m-%y'},
                tickInterval: totalMillis / 3.1
            },
            plotOptions: {
                series: {
                    events: {legendItemClick: function () {return false;}},
                    states: {hover: {enabled: false}, inactive: {opacity: 1}},
                    marker: {enabled: false}
                },
                arearange: {
                    connectNulls: true
                }
            },
            legend: {
                enabled: hasLegend,
                floating: true,
                verticalAlign: 'top',
                align: 'right',
                itemStyle: {
                    color: '#fff',
                    fontWeight: 'normal',
                    fontSize: fontSize,
                },
                itemHoverStyle: {
                    color: '#fff',
                    cursor: 'default'
                },
                x: tallScreen ? 15 : (smallScreen ? 5 : 14),
                y: tallScreen ? -18 : (smallScreen ? -14 : -16),
                symbolRadius: 0,
                symbolHeight: tallScreen ? 15 : (smallScreen ? 11 : 13),
                symbolWidth: tallScreen ? 15 : (smallScreen ? 11 : 13)
            },
            tooltip: {
                crosshairs: {color: 'red', dashStyle: 'solid'},
                shared: true,
                followPointer: true,
                formatter: function() {
                    const seriesPointMapping = {};
                    this.points.forEach((p, idx) => {
                        seriesPointMapping[p.series.name] = p;
                    });
                    const finalPoints = [];
                    this.points.forEach((p, idx) => {
                        const name = p.series.name;
                        if (name.substr(0, 4) === 'data') {
                            const point = {
                                color: p.color,
                                value: p.y
                            };
                            const errorPoint = seriesPointMapping[`error${p.series.name.substr(4)}`];
                            if (errorPoint !== undefined) {
                                const low = Math.round(errorPoint.point.low * 100) / 100;
                                const high = Math.round(errorPoint.point.high * 100) / 100;
                                point.value = `${point.value} <span style="color: #aaa">(${low} - ${high})</span>`;
                            }
                            finalPoints.push(point);
                        }
                    });
                    return `<b>${moment(this.x).format('YYYY-MM-DD')}</b><br/>` +
                        `${finalPoints
                            .map(p => `<span style="color:${p.color}">\u25A0</span> ${p.value}`)
                            .join('<br/>')}`;
                },
                useHTML: true,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                borderWidth: 0,
                style: {
                    color: '#fff'
                },
            }
        }}
    />;
};

LineChart.propTypes = {
    series: PropTypes.array,
    height: PropTypes.number,
    buffer: PropTypes.number,
    allowDecimalTicks: PropTypes.bool,
    allowNegativeTicks: PropTypes.bool,
    allowMargin: PropTypes.bool,
    selfRound: PropTypes.bool
};

LineChart.defaultProps = {
    height: 150,
    buffer: 0.2,
    allowDecimalTicks: true,
    allowNegativeTicks: true,
    allowMargin: true,
    selfRound: false
};

export default LineChart;