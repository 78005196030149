import {useState, useEffect, useMemo} from 'react';

const useGetData = ({url, defaultValue}) => {
    if(defaultValue === undefined) defaultValue = null;
    const [data, setData] = useState(defaultValue);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        if (url === null) setData(defaultValue)
        else {
            fetch(url).then(response => response.ok ? response.json() : defaultValue)
                .then(data => setData(data))
        }
    }, [url, counter]);

    const refreshData = () => {setCounter(counter + 1)};

    return [data, refreshData];
};

export {useGetData};
