import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const CurrentDate = ({refresh, refreshIntervalSeconds}) => {
    const [now, setNow] = useState(moment());
    useEffect(() => {
        setTimeout(() => {
            setNow(moment());
            refresh();
        }, refreshIntervalSeconds*1000);
    }, [now, refresh, refreshIntervalSeconds]);

    return null;
};

CurrentDate.propTypes = {
    refresh: PropTypes.func.isRequired,
    refreshIntervalSeconds: PropTypes.number.isRequired
};
CurrentDate.defaultProps = {
    refresh: () => {},
    refreshIntervalSeconds: 60
};

export default CurrentDate;