import React from 'react';
import { useEffect } from "react";
import 'mapbox-gl-leaflet';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl-leaflet/leaflet-mapbox-gl';
import 'leaflet/dist/leaflet.css';
import { useLeafletContext } from '@react-leaflet/core';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl-leaflet/leaflet-mapbox-gl';
import L from 'leaflet';

const CrisisTriggerLayer = ({ data, showCountriesInCrisis }) => {
    const context = useLeafletContext();

    const rbpData =  data.features.filter(feature => feature.properties.trigger === true);

    const geoJsonLayerStyle = {
        color: '#12d4de', 
        weight: 1,        
        opacity: 1, 
        fill:false
    };

    const filteredGeoJSON = {
        type: 'FeatureCollection',
        features: rbpData,
    }

    useEffect(() => {
        var geoJsonLayer = L.geoJSON(filteredGeoJSON, {
            style: geoJsonLayerStyle
        }).addTo(context.map) 


        if(showCountriesInCrisis === false) {
            geoJsonLayer.clearLayers()
            geoJsonLayer.remove();
            context.map.removeLayer(geoJsonLayer)
        }

        return () => {
            geoJsonLayer.remove();
            context.map.removeLayer(geoJsonLayer)
        };

    }, [data, showCountriesInCrisis])
}

  export default CrisisTriggerLayer;
  
