import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { useMap, LayerGroup} from "react-leaflet";
import './AcledScatter.scss';
import L, {CircleMarker} from "leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import 'leaflet.markercluster.placementstrategies';

import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import { useLeafletContext } from '@react-leaflet/core';

const acledColors = {
    'Battles': 'RGBA(134,0,47,0.9)',
    'Explosions/Remote violence': 'RGBA(255,187,122,0.9)',
    'Violence against civilians': 'RGBA(162,200,236,0.9)',
    'Riots': 'RGBA(201,82,0,0.9)',
    'Protests': 'RGBA(13,101,125,0.9)',
    'Strategic developments': 'RGBA(207,207,207,0.9)'
};
const labels = {
    'Battles': {singular: 'Battle', plural: 'Battles'},
    'Explosions/Remote violence': {singular: 'Explosion/Remote violence', plural: 'Explosions/Remote violences'},
    'Violence against civilians': {singular: 'Violence against civilians', plural: 'Violences against civilians'},
    'Riots': {singular: 'Riot', plural: 'Riots'},
    'Protests': {singular: 'Protest', plural: 'Protests'},
    'Strategic developments': {singular: 'Strategic development', plural: 'Strategic developments'}
};

const clusterPixelRadius = 60;

Math.log10 = Math.log10 || function(x) {
    return Math.log(x) * Math.LOG10E;
};

const AcledScatter = ({data, pane}) => {
    const selectedClusterRef = useRef(null);
    const leafletContext = useLeafletContext();
    const map = leafletContext.map;
    
    const radiusFromCount = count => 4 * Math.log10(count) + 3;

    const createClusterGroup = (markers, eventType) => {
        const mcg = L.markerClusterGroup({
            spiderLegPolylineOptions: {weight: 0},
            elementsPlacementStrategy: 'original-locations',
            disableClusteringAtZoom: 6,
            zoomToBoundsOnClick: false,
            maxClusterRadius: clusterPixelRadius,
            spiderfyOnMaxZoom: false,
            polygonOptions: {
                fillColor: '#fff',
                fillOpacity: 0.1,
                color: '#fff',
                opacity: 0.2,
                weight: 2,
                dashArray: [3, 3]
            },
            iconCreateFunction: (cluster) => {
                const markers = cluster.getAllChildMarkers();
                const total = markers.map(m => m.options.eventNumber).reduce((acc, v) => acc + v);
                const radius = radiusFromCount(total);
                const clusterColor = acledColors[eventType];
                const icon = L.divIcon({
                    html: `<div class="acledCluster" style="background-color: ${clusterColor}; 
                        width: ${radius*2}px; height: ${radius*2}px">&nbsp;</div>`,
                    className: 'acledClusterContainer',
                    iconSize: L.point(radius*2, radius*2)
                });
                return icon;
            }
        });
        mcg.on('clusterclick', function(c) {
            const markers = c.layer.getAllChildMarkers();
            const total = markers.map(m => m.options.eventNumber).reduce((acc, v) => acc + v);
            const eventType = markers[0].options.eventType;
            L.popup({className: 'acledPopup'})
                .setLatLng(c.layer.getLatLng())
                .setContent(`${total} ${labels[eventType][total === 1 ? 'singular' : 'plural']}`)
                .openOn(map);
            selectedClusterRef.current = c;
        });
        markers.filter(m => m.options.eventType === eventType).forEach(marker => marker.addTo(mcg));
        return mcg;
    };

    useEffect(() => {
        if(map == null){
            return;
        }
        const markers = data.features.map(({geometry: {coordinates: [lng, lat]}, properties: {count, event_type}}) => {
            const marker = new CircleMarker({lat, lng}, {
                radius: radiusFromCount(count),
                color: '#fff',
                opacity: 0.7,
                weight: 1,
                fillOpacity: 1,
                fillColor: acledColors[event_type],
                pane: pane,
                eventNumber: count,
                eventType: event_type
            });
            marker.bindPopup(`${count} ${labels[event_type][count === 1 ? 'singular' : 'plural']}`,
                {className: 'acledPopup'});
            return marker;
        });

        const clusters = [];
        for (const eventType in labels) {
            clusters.push(createClusterGroup(markers, eventType));
        }
        clusters.forEach(cluster => map.addLayer(cluster));

        const popupHandler = () => {
            selectedClusterRef.current = null;
        };
        const zoomHandler = () => {
            if (selectedClusterRef.current !== null && selectedClusterRef.current.layer._icon === null) {
                selectedClusterRef.current = null;
                map.closePopup();
            }
        };
        map.on('popupclose', popupHandler);
        map.on('zoomend', zoomHandler);

        return () => {
            map.closePopup();
            clusters.forEach(cluster => map.removeLayer(cluster));
            map.off('popupclose', popupHandler);
            map.off('zoomend', zoomHandler);
        };
    }, [createClusterGroup, data, map, pane]);

    return null;
};

AcledScatter.propTypes = {
    data: PropTypes.object.isRequired,
    pane: PropTypes.string
};

export default AcledScatter;