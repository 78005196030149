import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMap } from 'react-leaflet';
import 'mapbox-gl-leaflet';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl-leaflet/leaflet-mapbox-gl';
import 'leaflet/dist/leaflet.css';
import { useLeafletContext } from '@react-leaflet/core';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl-leaflet/leaflet-mapbox-gl';


const VectorTileLayer = ({ url }) => {
  const { map } = useMap();
  const mapContainerRef = useRef(null);
  const mbMapRef = useRef(null);


  const context = useLeafletContext();
  const mapContainer = useRef();
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY

  useEffect(() => {
    let baseMap = new mapboxgl.Map({ 
      container: mapContainer.current,
      style: url,
      interactive: true,
      noWrap: true
    });

    baseMap.dragRotate.disable();

    const syncZoom = () => {
        baseMap.setZoom(context.map.getZoom()-1)
        const { lat, lng } = context.map.getCenter()
        baseMap.setMaxZoom(context.map.getMaxZoom()-1)
        baseMap.setMinZoom(context.map.getMinZoom()-1)
    }

    const container = context.layerContainer || context.map;
    const leafletMap = container.getContainer();
    leafletMap.appendChild(mapContainer.current);

    baseMap.setZoom(context.map.getZoom());
    baseMap.setMaxZoom(context.map.getMaxZoom()-1)
    baseMap.setMinZoom(context.map.getMinZoom()-1)
    const { lat, lng } = context.map.getCenter()
    baseMap.setCenter([lng, lat]);
    baseMap.setZoom(context.map.getZoom()-1)

    context.map.on('move', () => {
      const { lat, lng } = context.map.getCenter();
      baseMap.setCenter([lng, lat]);
      syncZoom()
    });

    context.map.on('zoom', () => {
      const { lat, lng } = context.map.getCenter();
      baseMap.setCenter([lng, lat]);
      syncZoom()
    });

    context.map.on('movestart', () => {
      const { lat, lng } = context.map.getCenter();
      baseMap.setCenter([lng, lat]);
      syncZoom()
    });

    context.map.on('zoomstart', () => {
      syncZoom()
    });

    context.map.on('moveend', () => {
      const { lat, lng } = context.map.getCenter();
      baseMap.setCenter([lng, lat]);
      syncZoom()
    });

    context.map.on('zoomend', () => {
      const { lat, lng } = context.map.getCenter();
      baseMap.setCenter([lng, lat]);
      syncZoom()
    });

  return () => {
      baseMap.remove();
      context.map.off('move');
  };
  }, [url, context]);
  
  return <div ref={mapContainer} style={{width: '100%', height: '100%', zIndex: 2 }} />;
}

VectorTileLayer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default VectorTileLayer;