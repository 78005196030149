import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import './CountryNotes.scss';
import {imgFile} from "./utils";


const CountryNotes = ({ notes }) => {
    const [showPopUp, setShowPopUp] = useState(notes);

    useEffect(() => {
        setShowPopUp(notes);
    }, [notes]);

    return (
        <>
        { notes &&
            <div className={`countryModalContainer ${showPopUp ? 'active' : ''}`}>
                <div className={`popupBox countryNotes `}>
                    <div className='topright'>
                        <img src={imgFile('close_icon.png')}
                             className='link' alt='' onClick={() => setShowPopUp(false)} />
                    </div>
                    <div className='content'>
                        {  notes && notes.map(x => (
                            <span dangerouslySetInnerHTML={{__html: x.note}}/>
                        )) }
                    </div>
                </div>
            </div>
        }
        </>
    )
}

CountryNotes.propTypes = {
    notes: PropTypes.array
};

CountryNotes.defaultProps = {
    notes: null
};

export default CountryNotes;