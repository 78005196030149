import React from 'react';
import { useState } from "react";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Collapsable.scss';

const Collapsable = ({ children, expanded, header, headerExpanded, contentCollapsed, options }) => {
  const [isExpanded, setExpanded] = useState(expanded);
  const currentHeader = isExpanded && headerExpanded ? headerExpanded : header;

  const togglePanel = e => {
    setExpanded(!isExpanded);
  };

  const iconClassName = classNames('icon',{
    'iconUpDown': !options.collapseRight,
    'iconRightLeft': options.collapseRight,
    'iconExpanded': isExpanded && !options.collapseDown || !isExpanded && options.collapseDown,
    'iconCollapsed': !isExpanded ||  isExpanded && options.collapseDown
  })
  const contentClassName = classNames('collapsableContent')

  return (
    <span className="collapsable">
      { contentCollapsed && !isExpanded ?
        null: options.iconToggled ? 
        <div className="collapsableHeader">
          <div className="collapseTitle">
            {currentHeader}
          </div>
          <div className="collapseIcon" onClick={togglePanel}>
            <svg className={iconClassName} viewBox="6 0 12 24">
              <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
            </svg>
          </div>
        </div>
        : <div onClick={togglePanel} className="collapsableHeader">
          {currentHeader}
          <div className="collapseIcon">
            <svg className={iconClassName} viewBox="6 0 12 24">
              <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
            </svg>
          </div>
        </div>
      }
      {
        isExpanded? <div className={contentClassName}>{children}</div> :
            contentCollapsed ? <div className={contentClassName} onClick={togglePanel} >{contentCollapsed}</div> : null
      }
    </span>
  );
};


Collapsable.propTypes = {
  expanded: PropTypes.bool,
  header: PropTypes.object,
  headerExpanded: PropTypes.object,
  contentCollapsed: PropTypes.object,
  options: PropTypes.object
};

Collapsable.defaultProps = {
  expanded: true,
  options: { iconToggled : false } 
};

export default Collapsable;