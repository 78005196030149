import React, {useEffect, forwardRef} from 'react';
import PropTypes from 'prop-types';
import Select, {components} from 'react-select';
import {useWindowHeight} from '@react-hook/window-size';

import './CountrySelector.scss';
import constants from "./constants";

const CountrySelector = forwardRef(({options, active, onChange, onFocusChanged}, ref) => {
    const windowHeight = useWindowHeight();
    const tallScreen = windowHeight > constants.tallScreenMinHeight;
    const smallScreen = windowHeight <= constants.mediumScreenMinHeight;

    const CountryOption = (props) => {
        useEffect(() => {
            if (props.isFocused) {
                onFocusChanged(props.value.adm0_id);
            }
        }, [props.isFocused]);
        return <div><components.Option {...props} /></div>;
    };

    const fontSize = tallScreen ? '10pt' : (smallScreen ? '7pt' : '8pt');
    const height = tallScreen ? 30 : (smallScreen ? 20 : 25);

    const customStyle = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'RGBA(0, 0, 0, 0.6)',
            fontSize: fontSize,
            borderColor: `RGBA(255, 255, 255, ${state.isFocused ? '0.65' : '0.5'})`,
            boxShadow: state.isFocused ? 0 : 0,
            borederRadius: 2,
            height: height,
            minHeight: height,
            '&:hover': {
                borderColor: 'RGBA(255, 255, 255, 0.65)',
            }
        }),
        input: (provided, state) => ({
           ...provided,
            color: `RGBA(255, 255, 255, ${state.isFocused ? 0.65 : 0.5})`,
        }),
        singleValue: (provided) => {
            const opacity = 0.5;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        },
        placeholder: (provided) => ({
            ...provided,
            top: tallScreen ? 12 : (smallScreen ? 8 : 10),
            paddingBottom: 6
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: height
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: 'RGBA(255, 255, 255, 0.5)',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: `RGBA(255, 255, 255, ${state.isFocused ? 0.65 : 0.5})`,
            width: height * 1.3,
            height: height,
            paddingTop: tallScreen ? '5px' : (smallScreen ? '0' : '3px'),
            '&:hover': {
                color: 'RGBA(255, 255, 255, 0.65)',
            }
        }),
        menu: (provided) => ({
            ...provided,
            fontSize: fontSize,
            color: 'rgba(255, 255, 255, 0.8)',
            backgroundColor: 'RGBA(0, 0, 0, 0.8)',
            zIndex: 1200
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#018FA9' : 'RGBA(0, 0, 0, 0)'
        })
    };

    return <div className={`countrySelector ${active ? 'active' : ''}`}>
        <div className={'container'}>
            <p className={'label'}>Navigate</p>
            <Select options={options} styles={customStyle} placeholder={'Select a country'} value={null}
                onChange={onChange} components={{Option: CountryOption}} onMenuClose={() => {onFocusChanged(null)}}
                ref={ref}/>
        </div>
    </div>;
});

CountrySelector.propTypes = {
    active: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.array,
    onFocusChanged: PropTypes.func
};
CountrySelector.defaultProps = {
    active: false,
    onChange: () => {},
    onFocusChanged: () => {}
};

export default CountrySelector;