import React from 'react';
import PropTypes from 'prop-types';
import './MapMenu.scss';
import classNames from 'classnames';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward.css';
import {useWindowHeight} from '@react-hook/window-size';
import {imgFile} from "./utils";
import constants from "./constants";

const MapMenu = ({activeTabs, onSelect, hasUndernourishment, hasIpc, isIntro, introActiveItems, introTooltip,
                 conflictDisabled}) => {
    const itemAttrs = name => ({
        name,
        isIntro,
        introActive: introActiveItems.includes(name),
        introTooltipOpen: introTooltip === name,
        onClick: onSelect,
        active: activeTabs.indexOf(name) > -1,
        key: `button_${name}_${isIntro ? 'intro' : 'normal'}_${introActiveItems.includes(name) ? 'active' : 'inactive'}`
    });

    return <div className={`${isIntro ? 'intro ' : ''} MapMenu`}>
        <div className={'inner'}>
            <MenuItem {...itemAttrs('pdc')} label='Hazards' icon={imgFile('menu_hazards.png')} alwaysActive/>
            <MenuItem {...itemAttrs('acled')} label='Conflict' icon={imgFile('menu_conflicts.png')}
                        className='conflict' alwaysActive disabled={conflictDisabled == 'true' ? true : false}/>
            <MenuItem {...itemAttrs('fcs')} label='Current Food Consumption' icon={imgFile('menu_fcs.png')}
                      className={'fcs'}/>
            <MenuItem {...itemAttrs('ndvi')} label='Vegetation' icon={imgFile('menu_ndvi.png')}/>
            <MenuItem {...itemAttrs('rainfall')} label='Rainfall' icon={imgFile('menu_rainfall.png')}/>
            {/* <MenuItem {...itemAttrs('ipc')} label='IPC/CH' icon={imgFile('menu_ipc.png')} disabled={!hasIpc}
                      disabledIcon={imgFile('menu_ipc_disabled.png')} className={'ipc'}/> */}
        </div>
    </div>;
};
MapMenu.propTypes = {
    activeTabs: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func.isRequired,
    hasUndernourishment: PropTypes.bool.isRequired,
    hasIpc: PropTypes.bool.isRequired,
    isIntro: PropTypes.bool,
    introActiveItems: PropTypes.array.isRequired,
    introTooltip: PropTypes.string
};
MapMenu.defaultProps = {
    onSelect: () => {},
    isIntro: false,
    introActiveItems: []
};

const MenuItem = ({name, label, icon, disabledIcon, className, active, onClick, disabled, isIntro, introActive,
                      introTooltipOpen, alwaysActive}) => {
    const windowHeight = useWindowHeight();
    const smallScreen = windowHeight <= constants.mediumScreenMinHeight;
    const tooltipDistance = smallScreen ? 15 : 30;
    const itemClass = classNames('MenuItem', className, {
        active: active && !disabled,
        disabled: disabled,
        intro: isIntro,
        clickable: (!active || alwaysActive) && !disabled});
    return <div className={itemClass}>
        <Tippy content='Find out more here' visible={introTooltipOpen} hideOnClick={false}
               className={'introTooltip ' + name} animation={'shift-toward'}
               popperOptions={{modifiers: {preventOverflow: {escapeWithReference : true}}}} offset={[0, tooltipDistance]}>
            <div className={'title' + (introActive ? ' highlighted' : '')} data-tip data-for={'tooltip_' + name}>
                {label}
            </div>
        </Tippy>
        <div onClick={() => {if ((!active || alwaysActive) && !disabled) onClick(name)}}><img
            src={(disabled && disabledIcon) ?
            disabledIcon : icon} alt={''} className={'icon' + (introActive ? ' pulse' : '')}/></div>
        <div><img src={(disabled && disabledIcon) ? disabledIcon : icon} alt={''} className={'reflection' +
            (introActive ? ' pulseReflection' : '')}/></div>
    </div>;
};
MenuItem.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    disabledIcon: PropTypes.string,
    className: PropTypes.string,
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    introActive: PropTypes.bool.isRequired,
    isIntro: PropTypes.bool.isRequired,
    introTooltipOpen: PropTypes.bool.isRequired,
    alwaysActive: PropTypes.bool,
    conflictDisabled: PropTypes.bool
};
MenuItem.defaultProps = {
    active: false,
    onClick: () => {},
    disabled: false,
    introActive: false,
    alwaysActive: false,
    conflictDisabled: false
};


export default MapMenu;