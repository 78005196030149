const fixGeoData = (feature, layer) => {
    // inspired by https://stackoverflow.com/a/29420685
    const bounds = layer.getBounds && layer.getBounds();
    if (layer.feature.geometry.coordinates.length === 0) return;
    if (Math.abs(bounds.getEast() - bounds.getWest()) === 360) {
        const latlongs = layer.getLatLngs();
        latlongs.forEach(multiShape => {
            if (multiShape.length > 0 && multiShape.lng !== undefined) {
                // this is a single shape
                const shape = multiShape;
                shape.forEach(latLon => {
                    if (latLon.lng < 0) {
                        latLon.lng += 360;
                    }
                });
            } else {
                // this is an actual multi shape
                multiShape.forEach(shape => {
                    shape.forEach(latLon => {
                        if (latLon.lng < 0) {
                            latLon.lng += 360;
                        }
                    });
                });
            }
        });
        layer.setLatLngs(latlongs);
    }
};

export default fixGeoData;