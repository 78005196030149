import React from 'react';
import { useState } from "react";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Collapsable.scss';

const CollapsableCrisisToggle = ({ title, children, expanded, header, headerExpanded, contentCollapsed, options,
    name, showAlerts, setShowAlerts, showCountriesInCrisis, setShowCountriesInCrisis }) => {
  const [isExpanded, setExpanded] = useState(expanded);
  const currentHeader = isExpanded && headerExpanded ? headerExpanded : header;
  const [isOn, setIsOn] = useState(false);
  const [toggle, setToggle] = useState(true);


  // Handler for click events
  const handleClick = () => {
    setIsOn(!isOn);
  };

  const handleToggle = () => {
      if(!toggle) {
          setToggle(false)
      }
      if (name == "alerts") {
        setShowAlerts(!showAlerts)
      }
  
      if (name == "crisis") {
        setShowCountriesInCrisis(!showCountriesInCrisis)
      }
  }

  const togglePanel = e => {
    setExpanded(!isExpanded);
    setIsOn(!isOn)
    if (name == "alerts") {
      setShowAlerts(!showAlerts)
    }

    if (name == "crisis") {
      setShowCountriesInCrisis(!showCountriesInCrisis)
    }
  };
  const SvgOn = (
      <div style={{ marginLeft: '5px'}}>
        <svg width="51.744" height="21.168" onClick={handleClick} style={{cursor: 'pointer'}}>
          <rect x="0" y="0" width="51.072" height="18.816" fill="black" rx="10.752" ry="10.752" stroke="gray" strokeWidth="0.672"/>
          <rect x="28.224" y="2.352" width="21.168" height="14.112" fill="rgba(92, 188, 219)" rx="7.5264" ry="7.5264" />
          <text x="4.704" y="12.7008" fill="rgba(92, 188, 219)" fontWeight="bold" fontFamily="Arial" fontSize="7.056">ON</text>
        </svg>
      </div>
  );

  const SvgOff = (
    <div style={{ marginLeft: '5px'}}>
    <svg width="51.744" height="20.814" onClick={handleClick} style={{cursor: 'pointer'}}>
      <rect x="0" y="0" width="51.072" height="18.504" fill="black" rx="11.088" ry="11.088" stroke="gray" strokeWidth="0.686"/>
      <rect x="2.301" y="2.301" width="20.814" height="13.842" fill="rgba(112, 104, 104)" rx="6.915" ry="6.915" />
      <text x="29.923" y="12.429" fill="rgba(64, 61, 61)" fontWeight="bold" fontFamily="Arial" fontSize="6.915">OFF</text>
    </svg>
    </div>
  );
  

  const iconClassName = classNames('icon',{
    'iconUpDown': !options.collapseRight,
    'iconRightLeft': options.collapseRight,
    'iconExpanded': isExpanded && !options.collapseDown || !isExpanded && options.collapseDown,
    'iconCollapsed': !isExpanded ||  isExpanded && options.collapseDown
  })
  const contentClassName = classNames('collapsableContent')

  return (
    <span className="collapsable">
      { contentCollapsed && isExpanded ?
       null: options.iconToggled ? 
          <div className="collapseIcon" onClick={handleToggle}>
          </div>
        : <div onClick={togglePanel} className="collapsableHeader">
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingTop: '10px' }}>
                <span style={{ fontSize: '14px', color: 'white', paddingBottom: '6px', paddingRight: '4px' }}>{title}</span>
                {isOn ? SvgOff : SvgOn}
            </div>
        </div>
      }
        <div>
    </div>
      {
        isExpanded? <div className={contentClassName}>{children}</div> :
            contentCollapsed ? <div className={contentClassName} onClick={togglePanel} >{contentCollapsed}</div> : null
      }
    </span>
  );
};


CollapsableCrisisToggle.propTypes = {
  expanded: PropTypes.bool,
  header: PropTypes.object,
  headerExpanded: PropTypes.object,
  contentCollapsed: PropTypes.object,
  options: PropTypes.object
};

CollapsableCrisisToggle.defaultProps = {
  expanded: true,
  options: { iconToggled : false } 
};

export default CollapsableCrisisToggle;