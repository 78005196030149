import React from 'react';
import PropTypes from 'prop-types';
import './CountryPanes.scss';
import LineChart from './LineChart';
import BarChart from './BarChart';
import moment from 'moment';
import SectionInfoHeader from "./SectionInfoHeader";
import Collapsable from './Collapsable';
import { useWindowHeight } from '@react-hook/window-size';
import constants from './constants';
import { imgFile, lastNotNull } from "./utils";

const formatNumber = (num) => {
    const rounded = parseFloat((Math.round(num) / 1000000 * 1)).toFixed(1);
    return rounded.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const formatNumberAdm0 = (num) => {
    const rounded = parseFloat(Math.round(num * 10) / 10).toFixed(1);
    return rounded.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const CountryPanes = ({ data, onInfoClick }) => {
    const windowHeight = useWindowHeight();
    const tallScreen = windowHeight > constants.tallScreenMinHeight;
    const smallScreen = windowHeight <= constants.mediumScreenMinHeight;
    const rightChartHeight = tallScreen ? 100 : (smallScreen ? 65 : 85);
    const leftChartHeight = tallScreen ? 150 : (smallScreen ? 105 : 130);

    const numberFormat = new Intl.NumberFormat('en-US');

    const fcsDifference = (newFcs, oldFcs, monthsAgo) => {
        if (newFcs === null || oldFcs === null) return <>
            <span className='smallNoData'>No data</span> from {monthsAgo} month{monthsAgo === 1 ? '' : 's'} ago
        </>;
        const diff = Math.round((newFcs - oldFcs) / 100000 * 10) / 100;
        if (diff === 0) return `Same as ${monthsAgo} months ago`;
        const imgSrc = (diff > 0 ? imgFile('arrow_purple.png') : imgFile('arrow_green.png'));
        const className = (diff > 0 ? 'positive' : 'negative');
        const diffType = (diff > 0 ? 'increase' : 'decrease');
        return <>
            <img className={className} src={imgSrc} alt='' />
            <span className={className}>{Math.abs(diff)} M
            </span> <br></br>{diffType} from {monthsAgo} month{monthsAgo === 1 ? '' : 's'} ago
        </>;
    };

    const graphRounding = (num) => {
        if (num === null)
            return null
        if (num === 0 )
            return 0
        return Math.round(num/1000000 * 1000) / 1000;
    };
    const fcsGraphData = data === null ? { data: [] } : {
        data: data.metrics.fcs.chart.map(({ date, fcs}) => ({
            x: new Date(date),
            y: graphRounding(fcs),
        })),
        unit: 'M',
        color: '#E08004'
    };
    const rcsiGraphData = data === null ? { data: [] } : {
        data: data.metrics.rcsi.chart.map(({ date, rcsi }) => ({
            x: new Date(date),
            y: graphRounding(rcsi),
        })),
        unit: 'M',
        color: '#E08004'
    };
    const combinedGraphData = data === null ? { data: [] } : {
        data: data.metrics.combined.chart.map(({ date, combined }) => ({
            x: new Date(date),
            y: graphRounding(combined),
        })),
        unit: 'M',
        color: '#E08004'
    };
    const currencyExchangeGraphData = data === null ? { data: [] } : {
        name: `Exchange rate (${data.currencyExchangeGraph.name.substr(0, 3)}/` +
            `${data.currencyExchangeGraph.name.substr(3)})`,
        data: data.currencyExchangeGraph.data.map(({ x, y }) => ({ x: new Date(x), y })),
        color: '#00A5FF'
    };
    const balanceOfTradeGraphData = data === null ? { data: [] } : {
        name: 'Balance of Trade',
        data: data.balanceOfTradeGraph.data.map(({ x, y }) => ({ x: new Date(x), y })),
        color: '#E08004'
    };
    const inflationGraphs = data === null ? [{ data: [] }, { data: [] }] : [{
        name: 'Headline Inflation',
        color: '#E08004',
        data: data.inflationGraphs.headline.data.map(({ x, y }) => ({ x: new Date(x), y }))
    }, {
        name: 'Food Inflation',
        color: '#00A5FF',
        data: data.inflationGraphs.food.data.map(({ x, y }) => ({ x: new Date(x), y }))
    }];
    const insightReportUrl = data === null ? null : data.insightReport;


    return <><div className={`countryPane leftPane ${data !== null ? 'active' : ''}`}>
        <div className={'leftDetailsContainer'}>
            <div className='foodSecurity section'>
                <Collapsable
                    expanded
                    options={{iconToggled: true}}
                    header={<><SectionInfoHeader onClick={() => onInfoClick('foodSecuritySection')} />
                        <div className='header'>Food Security</div></>}>
                    <div className='indicator'>
                        <img src={imgFile('country_population.png')} alt='' />
                        <div className='content'>
                            <span className='name'>Population</span>
                            <div className='value'>
                                {data !== null && data.population !== null ?
                                    <>{formatNumberAdm0(data.population)}<span className='unit'>M</span></> :
                                    <span className='nodata'>No Data</span>}
                            </div>
                            <div className='info'> </div>
                        </div>
                    </div>
                    <div className='indicator'>
                        <img src={imgFile('country_fcs.png')} alt='' />
                        <div className='content'>
                            <span className='name'>People with insufficient food consumption</span>
                            <div className='value'>
                                {data !== null && data.metrics.fcs.latest.people !== null ?
                                    <>{formatNumber(data.metrics.combined.latest.people)}<span className='unit'>M</span></> :
                                    <span className='nodata'>No Data</span>}
                            </div>
                            <div className='info'>
                                <div className='trendboxContainer'>
                                    <div className='trendbox'>
                                        {data !== null && fcsDifference(data.metrics.fcs.latest.people, data.metrics.fcs.three_months_ago.people, 3)}<br />
                                        {data !== null && fcsDifference(data.metrics.fcs.latest.people, data.metrics.fcs.one_month_ago.people, 1)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapsable>
            </div>
            {/* <div className='nutrition section'>
                <Collapsable
                    expanded
                    options={{iconToggled: true}}
                    header={<><SectionInfoHeader onClick={() => onInfoClick('nutritionSection')} />
                        <div className='header'>Nutrition</div></>}>
                    <div className='indicator'>
                        <img src={imgFile('country_acute_malnutrition.png')} alt='' />
                        <div className='content'>
                            <span className='name'>Acute malnutrition</span>
                            <div className='value'>
                                {data !== null && data.nutrition.wasting !== null ?
                                    <>{formatNumber(data.nutrition.wasting)}<span className='unit'>%</span></> :
                                    <span className='nodata'>No Data</span>}
                            </div>
                            {data !== null && data.nutrition.wasting !== null &&
                            <div className='info'>of children (under 5)</div>}
                        </div>
                    </div>
                    <div className='indicator'>
                        <img src={imgFile('country_chronic_malnutrition.png')} alt='' />
                        <div className='content'>
                            <span className='name'>Chronic malnutrition</span>
                            <div className='value'>
                                {data !== null && data.nutrition.stunting !== null ?
                                    <>{formatNumber(data.nutrition.stunting)}<span className='unit'>%</span></> :
                                    <span className='nodata'>No Data</span>}
                            </div>
                            {data !== null && data.nutrition.stunting !== null &&
                            <div className='info'>of children (under 5)</div>}
                        </div>
                    </div>
                </Collapsable>
            </div> */}
            <div className='foodSecurityTrends section'>
                <Collapsable
                    expanded
                    options={{iconToggled: true}}
                    header={<><SectionInfoHeader onClick={() => onInfoClick('foodSecurityTrendsSection')} />
                        <div className='header' style={{paddingTop: '5px'}}>Food Security Trends</div></>}>
                    <div className='chartbox'>
                        <span className='info'>Trend of the number of people with insufficient food consumption</span>
                        {fcsGraphData.data.filter(({ y }) => y !== null).length > 1 ?
                            <LineChart series={[fcsGraphData]} buffer={0.2} height={leftChartHeight} /> :
                            <span className='graphNoData'>No Data</span>}
                    </div>
                    <div className='chartbox'>
                        <span className='info'>Trend of the number of people using crisis or above crisis food-based coping</span>
                        {rcsiGraphData.data.filter(({ y }) => y !== null).length > 1 ?
                            <LineChart series={[rcsiGraphData]} buffer={0.2} height={leftChartHeight} /> :
                            <span className='graphNoData'>No Data</span>}
                    </div>
                    <div className='chartbox'>
                        <span className='info'>Trend of the number of people at risk of food insecurity</span>
                        {rcsiGraphData.data.filter(({ y }) => y !== null).length > 1 ?
                            <LineChart series={[combinedGraphData]} buffer={0.2} height={leftChartHeight} /> :
                            <span className='graphNoData'>No Data</span>}
                    </div>
                </Collapsable>
            </div>
        </div>

    </div>
        {/*<div className={`countryPane insightReport ${insightReportUrl !== null ? 'active' : ''}`}>*/}
        {/*    <div className={'iconBox'}>*/}
        {/*        <a href={insightReportUrl} target="_blank" rel="noopener noreferrer">*/}
        {/*            <img src={imgFile('reports_insights.png')} /><br />Country Insights on Food Security*/}
        {/*        </a>*/}
        {/*    </div>*/}
        {/*</div>*/}
        <div className={`countryPane rightPane ${data !== null ? 'active' : ''}`}>
            { insightReportUrl !== null && <div className={'countryInsightDownload'}>
                <a className={'link'} href={insightReportUrl} target="_blank" rel="noopener noreferrer">
                    <img src={imgFile('download_icon.svg')} alt="" />
                    <b>Download today's report</b>
                </a>
            </div>}
            <div className={'rightDetailsContainer'}>
                <div className='macroEconomic section'>
                    <Collapsable
                        expanded
                        options={{iconToggled: true}}
                        header={<> <SectionInfoHeader onClick={() => onInfoClick('macroEconomicSection')} />
                            <div className='header'>Macro-economic</div> </>}>
                        <div className='indicator'>
                            <img src={imgFile('country_macro_economics.png')} alt='' />
                            <div className='content'>
                                <span className='name'>Import dependency</span>
                                <div className='value'>
                                    {data !== null && data.importDependency !== null ?
                                        <>{formatNumberAdm0(data.importDependency)}<span className='unit'>%</span></> :
                                        <span className='nodata'>No Data</span>}
                                </div>
                                {data !== null && data.importDependency !== null && <div className='info'>of cereals</div>}
                            </div>
                        </div>
                    </Collapsable>
                </div>
                <div className='currencyExchange section'>
                    {/* <Collapsable
                        expanded={false}
                        options={{iconToggled: true}}
                        header={<div className='header'>Currency exchange</div>} >
                        <div className='chartbox'>
                            {currencyExchangeGraphData.data.filter(({ y }) => y !== null).length > 1 ?
                                <LineChart series={[currencyExchangeGraphData]} height={rightChartHeight} /> :
                                <span className='graphNoData'>No Data</span>}
                        </div>
                    </Collapsable> */}
                </div>
                <div className='balanceOfTrade section'>
                    {/* <Collapsable
                        expanded={false}
                        options={{iconToggled: true}}
                        header={<div className='header'>Balance of trade</div>}>
                        <div className='chartbox'>
                            {balanceOfTradeGraphData.data.filter(({ y }) => y !== null).length > 1 ?
                                <LineChart series={[balanceOfTradeGraphData]} height={rightChartHeight} /> :
                                <span className='graphNoData'>No Data</span>}
                        </div>
                    </Collapsable> */}
                </div>
                <div className='headlineFoodInflation section'>
                    <Collapsable
                        expanded
                        options={{iconToggled: true}}
                        header={<div className='header'>Headline and food inflation</div>}>
                        <div className='chartbox'>
                            {(inflationGraphs[0].data.filter(({ y }) => y !== null).length > 1 ||
                                inflationGraphs[1].data.filter(({ y }) => y !== null).length > 1) ?
                                <LineChart series={inflationGraphs} height={rightChartHeight} /> :
                                <span className='graphNoData'>No Data</span>}
                        </div>
                    </Collapsable>
                </div>
            </div>
        </div>
    </>;
};

CountryPanes.propTypes = {
    data: PropTypes.object,
    onInfoClick: PropTypes.func.isRequired,
};

CountryPanes.defaultProps = {
    onInfoClick: () => {}
};

export default CountryPanes;