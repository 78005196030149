import React from 'react';
import PropTypes from 'prop-types';
import './SectionInfoHeader.scss';

const SectionInfoHeader = ({onClick}) => {
    return  <div className='infoHeader' onClick={onClick}>&nbsp;</div>;
};

SectionInfoHeader.propTypes = {
    onClick: PropTypes.func.isRequired
};
SectionInfoHeader.defaultProps = {
    onClick: () => {}
};

export default SectionInfoHeader;