import React, { useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import './Adm1Labels.scss';

const Adm1Labels = ({ features, iso3 }) => {
  const map = useMap();
  const layerGroupRef = useRef(L.layerGroup().addTo(map));

  useEffect(() => {
    const layerGroup = layerGroupRef.current;
    
    const countryData = features.filter(f => f.properties.iso3 === iso3);
    layerGroup.clearLayers();

    countryData.forEach(f => {
      const label = L.tooltip({
        permanent: true,
        direction: 'center',
        className: 'adm1Label',
      })
        .setContent(f.properties.name)
        .setLatLng([f.geometry.coordinates[1], f.geometry.coordinates[0]]);
  
      layerGroup.addLayer(label);
    });

    return () => {
      layerGroup.clearLayers();
      map.removeLayer(layerGroup);
    };
  }, [features, iso3, map]);

  return null;
};

Adm1Labels.propTypes = {
  data: PropTypes.any,
  iso3: PropTypes.string,
};

export default Adm1Labels;
