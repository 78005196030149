import React from 'react';
import PropTypes from 'prop-types';
import './MapZoomControl.scss';
import { useLeafletContext } from '@react-leaflet/core';

const MapZoomControl = ({global}) => {
    const context = useLeafletContext();

    return <div className={`zoomControl ${global ? '' : 'country'}`}>
        <div className={'zoomIn'} onClick={() => context.map.zoomIn()}>
            <span>+</span>
        </div>
        <div className={'zoomOut'} onClick={() => context.map.zoomOut()}>
            <span>&ndash;</span>
        </div>
    </div>;
};

MapZoomControl.propTypes = {
    global: PropTypes.bool.isRequired
};
MapZoomControl.defaultProps = {
    global: true
};

export default MapZoomControl;