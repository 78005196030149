import  { useEffect} from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { useLeafletContext } from '@react-leaflet/core';

import './AlertMarker.scss';

const AlertMarker = ({center, type, alertsGroup}) => {
    const leafletContext = useLeafletContext();
    const map = leafletContext.map;

    useEffect(() => {
        if(map == null){
            return;
        }

        const classNamesPerType = {
            'covid': 'covidAlert',
            'fcs': 'fcsAlert',
            'marketAccess': 'marketAccessAlert',
            'conflict': 'conflictAlert',
            'climateDry': 'climateDryAlert',
            'climateWet': 'climateWetAlert',
            'climate': 'climateDryAlert',
            'economic': 'climateWetAlert',
            'hazard': 'hazardAlert'

        };
        const className = classNamesPerType[type];
        const alertRadius = 5;

        const border1 = L.divIcon({className: 'borderContainer',
            html: `<div class="alertMarkerBorder border1 ${className}">&nbsp;</div>`});
        const border2 = L.divIcon({className: 'borderContainer',
            html: `<div class="alertMarkerBorder border2 ${className}">&nbsp;</div>`});
        const border3 = L.divIcon({className: 'borderContainer',
            html: `<div class="alertMarkerBorder border3 ${className}">&nbsp;</div>`});

        let markerCenter = L.latLng([center.latitude, center.longitude]);
        let xOffset = 0;
        let yOffset = 0;
        const alertsCount = Object.values(alertsGroup).filter(x => x === true).length;
        if (alertsCount > 1) {
            const alertId = Object.keys(alertsGroup).sort().filter(x => alertsGroup[x]).indexOf(type);
            const angle = 2 * Math.PI / alertsCount;
            xOffset = Math.cos(angle * alertId) * alertRadius * 0.4;
            yOffset = Math.sin(angle * alertId) * alertRadius * 0.8;
        }

        const point = map.latLngToContainerPoint(markerCenter);

        point.x += xOffset;
        point.y += yOffset; 

        const newPoint = L.point([point.x + xOffset, point.y + yOffset]);
        markerCenter = map.containerPointToLatLng(newPoint);

        const marker1 = L.marker(markerCenter, { icon: border1, interactive: false }).addTo(map);
        const marker2 = L.marker(markerCenter, { icon: border2, interactive: false }).addTo(map);
        const marker3 = L.marker(markerCenter, { icon: border3, interactive: false }).addTo(map);

        const circleMarker = L.circleMarker(markerCenter, {
            radius: alertRadius,
            className: `alertMarker ${className}`,
            interactive: false,
          });
        circleMarker.addTo(map);

        return () => {
            map.removeLayer(circleMarker);
            map.removeLayer(marker1);
            map.removeLayer(marker2);
            map.removeLayer(marker3);
        };
      }, [map, center, type, alertsGroup, leafletContext]); 
  
      return null; 

};

AlertMarker.propTypes = {
    center: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number
    }),
    type: PropTypes.oneOf(['covid', 'fcs','marketAccess', 'conflict', 'climateDry', 'climateWet', 'climate', 'hazard', 'economic']),
    alertsGroup: PropTypes.object
};
AlertMarker.defaultProps = {
    alertsGroup: {}
};

export default AlertMarker;