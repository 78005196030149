import React from 'react';
import './DisclaimerLinks.scss';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward.css';
import PropTypes from 'prop-types';

const DisclaimerLinks = ({onHomeClick, onClick, isIntro, introActiveItems, introTooltip}) => {
    const linkAttrs = name => ({
        className: `link ${introActiveItems.includes(name) ? 'active' : ''}`,
        onClick: () => onClick(name)
    });

    const Link = ({name, label}) => <>
        <Tippy content='Find out more here' visible={introTooltip === name} hideOnClick={false}
               className={'introTooltip ' + name} animation={'shift-toward'}
               popperOptions={{modifiers: {preventOverflow: {escapeWithReference : true}}}} offset={[0, 30]}>
            <span {...linkAttrs(name)}>{label}</span>
        </Tippy>
    </>

    return <div className={`disclaimerLinks ${isIntro ? 'intro' : ''}`}>
        <span className={'link'} onClick={() => onHomeClick()}>Home</span>
        <Link name='glossary' label='Glossary'/>
        <Link name='methodology' label='Methodology'/>
        {/* <Link name='disclaimer' label='Disclaimer'/> */}
    </div>
};

DisclaimerLinks.propTypes = {
    onHomeClick: PropTypes.func,
    onClick: PropTypes.func,
    isIntro: PropTypes.bool.isRequired,
    introActiveItems: PropTypes.array,
    introTooltip: PropTypes.string
};
DisclaimerLinks.defaultProps = {
    onHomeClick: () => {},
    onClick: () => {},
    isIntro: false,
    introActiveItems: []
};

export default DisclaimerLinks;