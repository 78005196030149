import React from 'react';
import PropTypes from 'prop-types';
import './Legends.scss';
import './AlertMarker.scss';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward.css';
import classNames from 'classnames';
import Collapsable from './Collapsable';
import { apiUrl, imgFile, ossPublicFile } from "./utils";
import SectionInfoHeader from './SectionInfoHeader';
import CollapsableCrisisToggle from './CollapsableCrisisToggle';
import AlertTypeLegend from "./components/AlertTypeLegend";
import NumberFormatterServiceImpl from "./services/NumberFormatterServiceImpl";

const Legends = ({ activeLayers, countryPage, onInfoClick, info, onSetPopupShown, legendCollapsed, onCollapseClick,
                 conflictDisabled, fcsAlertsDisabled, countriesInCrisis,
                 showCountriesInCrisis, showAlerts,
                 setShowCountriesInCrisis, setShowAlerts}) => {

    const numberFormatterService = new NumberFormatterServiceImpl();

    const reportsClassName = classNames('reports', 'legend', {
        active: (activeLayers.indexOf('fcs') > -1 || activeLayers.indexOf('rainfall') > -1 ||
            activeLayers.indexOf('ndvi') > -1) && !countryPage
    });
    const alertsLegendClassName = classNames('alerts', 'alertsLegend', {
        active: activeLayers.indexOf('fcs') > -1 && activeLayers.length === 1 && !countryPage
    });
    const countriesInCrisisClassName = classNames('alerts', 'alertsLegend', {
        active: activeLayers.indexOf('fcs') > -1 && activeLayers.length === 1 && !countryPage
    });
    const pdcClassName = classNames('pdc', 'legend', {
        countryPage,
        active: activeLayers.indexOf('pdc') > -1,
        withFcs: activeLayers.indexOf('fcs') > -1,
        withIpc: activeLayers.indexOf('ipc') > -1
    });
    const acledClassName = classNames('acled', 'legend', {
        countryPage,
        active: activeLayers.indexOf('acled') > -1,
        withFcs: activeLayers.indexOf('fcs') > -1,
        withIpc: activeLayers.indexOf('ipc') > -1
    });
    const fcsClassName = classNames('fcs', 'legend', {
        countryPage,
        active: activeLayers.indexOf('fcs') > -1
    });
    const ndviClassName = classNames('ndvi', 'legend', {
        countryPage,
        active: activeLayers.indexOf('ndvi') > -1
    });
    const rainfallClassName = classNames('rainfall', 'legend', {
        countryPage,
        active: activeLayers.indexOf('rainfall') > -1
    });
    const ipcGlobalClassName = classNames('ipcGlobal', 'legend', {
        active: !countryPage && activeLayers.indexOf('ipc') > -1
    });
    const ipcCountryClassName = classNames('ipcCountry', 'legend', {
        active: countryPage && activeLayers.indexOf('ipc') > -1
    });

    const rightLegendsClassName = classNames('topRightLegends', {
        active: !countryPage && activeLayers.indexOf('fcs') > -1
    });

    const collapsedClassName = classNames('collapsedLegend', {
        countryPage: countryPage,
    });

    const legendCollapseTrigger = (
        <div className="iconCollapse" onClick={onCollapseClick}>
            <svg viewBox="6 0 12 24">
                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
            </svg>
        </div>
    )

    return <>
        <span className={rightLegendsClassName} style={{marginTop: '34px'}}>
            <div className="alerts-wrapper">
                <CollapsableCrisisToggle expanded={true} title={"Countries in Crisis"}
                    name={"crisis"} showCountriesInCrisis={showCountriesInCrisis} setShowCountriesInCrisis={setShowCountriesInCrisis}> 
                    <div className={alertsLegendClassName} 
                      >
                        {countriesInCrisis.features.length == undefined || countriesInCrisis.features.length == 0  ?
                          <div>
                            <span style={{ fontWeight: 'bold', fontSize: '10px', paddingLeft: '35px'}}>No Countries Available</span>
                        </div> :
                        <div>
                            { countriesInCrisis.features
                                .map((feature) =>
                                    <>
                                    <div className={'content2'}>
                                        <div style={{ paddingBottom: '5px'}}>
                                            <div>
                                                <span style={{ fontWeight: 'bold', fontSize: '12px', paddingLeft: '35px'}}>{feature.properties.adm0_name}:</span>
                                                <span  style={{ fontWeight: 'bold', fontSize: '12px', color: 'rgba(92, 188, 219)', paddingLeft: '10px'}}>
                                                    {numberFormatterService.formatToMillion(feature.properties.food_security_numbers.combined)}M
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                )
                            }
                        </div>}
                    </div>
                </CollapsableCrisisToggle>

                <CollapsableCrisisToggle expanded={true} title={"Alerts"}
                         name={"alerts"} 
                         showAlerts={showAlerts} setShowAlerts={setShowAlerts}> 
                    <div className={alertsLegendClassName}>
                        {!fcsAlertsDisabled &&
                            <span>
                                <div className={'alertTitle'}>Hunger Alerts</div>
                                <div className={'content2'}>
                                    <div className={'marker'}>
                                        <div className={'alertMarkerBorder border1 fcsAlert'}>&nbsp;</div>
                                        <div className={'alertMarkerBorder border2 fcsAlert'}>&nbsp;</div>
                                        <div className={'alertMarkerBorder border3 fcsAlert'}>&nbsp;</div>
                                        <div className={'fcsAlert alertMarkerCore fcsAlertCore'}>&nbsp;</div>
                                    </div>
                                    <div className={'desc'}>Marked deterioration in food consumption from 90 days ago.</div>
                                </div>
                            </span>
                        }
                            <div className={'content'}>
                                <div className={'marker'}>
                                    <div className={'alertMarkerBorder border1 climateWetAlert'}>&nbsp;</div>
                                    <div className={'alertMarkerBorder border2 climateWetAlert'}>&nbsp;</div>
                                    <div className={'alertMarkerBorder border3 climateWetAlert'}>&nbsp;</div>
                                    <div className={'climateWetAlert alertMarkerCore climateWetAlertCore'}>&nbsp;</div>
                                </div>
                                <div className={'desc'}>
                                <span style={{ fontWeight: 'bold', fontSize: '10px'}}>Economic Shocks<br/></span>
                                        <span>Countries where food inflation is > 15%, between 10-15% but shows 33% or 66% deterioration month on month</span>
                                </div>
                            </div>
                        {conflictDisabled == 'false' && <span>
                            <div className={'content'}>
                                    <div className={'marker'}>
                                        <div className={'alertMarkerBorder border1 conflictAlert'}>&nbsp;</div>
                                        <div className={'alertMarkerBorder border2 conflictAlert'}>&nbsp;</div>
                                        <div className={'alertMarkerBorder border3 conflictAlert'}>&nbsp;</div>
                                        <div className={'conflictAlert alertMarkerCore conflictAlertCore'}>&nbsp;</div>
                                    </div>
                                    <div className={'desc'}>
                                        <span style={{ fontWeight: 'bold', fontSize: '10px'}}>Conflict<br/></span>
                                        <span>Initiated based on conflict-related fatalities or the increases in riots/ protests, with >= 1 fatality/200,000 in the last 30 days. </span>
                                    </div>
                                </div>
                            </span>}
                            <span>
                                <div className={'content'}>
                                    <div className={'marker'}>
                                        <div className={'alertMarkerBorder border1 climateDryAlert'}>&nbsp;</div>
                                        <div className={'alertMarkerBorder border2 climateDryAlert'}>&nbsp;</div>
                                        <div className={'alertMarkerBorder border3 climateDryAlert'}>&nbsp;</div>
                                        <div className={'ndviAlert alertMarkerCore climateDryAlertCore'}>&nbsp;</div>
                                    </div>
                                    <div className={'desc'}>
                                        <span style={{ fontWeight: 'bold', fontSize: '10px'}}>Climate Shocks<br/></span>
                                        <span>% of people living in areas with significant excess rainfall > 15% for at least 33% of the days in a 6-month period “in-season”.</span>
                                    </div>
                                </div>
                            </span>
                            <span>
                                <div className={'content'}>
                                    <div className={'marker'}>
                                        <div className={'alertMarkerBorder border1 hazardAlert'}>&nbsp;</div>
                                        <div className={'alertMarkerBorder border2 hazardAlert'}>&nbsp;</div>
                                        <div className={'alertMarkerBorder border3 hazardAlert'}>&nbsp;</div>
                                        <div className={'ndviAlert alertMarkerCore hazardAlertCore'}>&nbsp;</div>
                                    </div>
                                    <div className={'desc'}>
                                        <span style={{ fontWeight: 'bold', fontSize: '10px'}}>Hazards<br/></span>
                                        <span>Countries hit by a tropical storm, hurricane, earthquake (above 5.0 on the Richter scale) or floods in the last 2 months.</span>
                                    </div>
                                </div>
                            </span>
                            </div>
                        </CollapsableCrisisToggle>
            </div>
        </span>
        {legendCollapsed ?
            activeLayers.indexOf('undernourishment') > -1 ? null :
                <Tippy content='Click to restore legends' hideOnClick={true} arrow={true} className={'legendIconToolTip'} placement="left-start">
                    <div className={collapsedClassName} onClick={onCollapseClick} >
                        <span>i</span>
                    </div>
                </Tippy>
            : <div className={'legendsWrapper'}>
                <div className={pdcClassName}>
                    <SectionInfoHeader onClick={() => onInfoClick('pdcLegend')}/>
                    {legendCollapseTrigger}
                    <div className='text'>
                        Hazard type: Click on each icon to see the hazard type<br />
                        Hazard severity (visible on click):
                    </div>
                    <div className='icons'>
                        <img src={imgFile('pdc_marker_warning.png')} alt='' />
                        <span className='warning'>Warning</span>
                        <img src={imgFile('pdc_marker_watch.png')} alt='' />
                        <span className='watch'>Watch</span>
                        <img src={imgFile('pdc_marker_advisory.png')} alt='' />
                        <span className='advisory'>Advisory</span>
                        <img src={imgFile('pdc_marker_information.png')} alt='' />
                        <span className='information'>Information</span>
                        <img src={imgFile('pdc_marker_termination.png')} alt='' />
                        <span className='termination'>Termination</span>
                    </div>
                </div>
                <div className={acledClassName}>
                    <SectionInfoHeader onClick={() => onInfoClick('acledLegend')}/>
                    {legendCollapseTrigger}
                    <div className='item'><span className='battles marker'>&nbsp;</span>Battles</div>
                    <div className='item'><span className='violence marker'>&nbsp;</span>Violence against civilians</div>
                    <div className='item'><span className='explosions marker'>&nbsp;</span>Explosions/remote violence</div>
                    <div className='item'><span className='riots marker'>&nbsp;</span>Riots</div>
                    <div className='item'><span className='protests marker'>&nbsp;</span>Protests</div>
                    <div className='item'><span className='developments marker'>&nbsp;</span>Strategic developments</div>
                </div>
                <div className={fcsClassName}>
                    <SectionInfoHeader onClick={() => onInfoClick('fcsLegend')}/>
                    {legendCollapseTrigger}
                    <span className='header'>Risk of food </span>
                    <table>
                        <tbody>
                            <tr>
                                <td className='cell1 marker'>&nbsp;</td>
                                <td className='cell2 marker'>&nbsp;</td>
                                <td className='cell3 marker'>&nbsp;</td>
                                <td className='cell4 marker'>&nbsp;</td>
                                <td className='cell5 marker'>&nbsp;</td>
                                <td className='cell6 marker'>&nbsp;</td>
                            </tr>
                            <tr className='descriptions'>
                                <td>Very Low</td>
                                <td>Low</td>
                                <td>Moderately low</td>
                                <td>Moderately high</td>
                                <td>High</td>
                                <td>Very high</td>
                            </tr>
                            <tr>
                                <td>0-5%</td>
                                <td>5-10%</td>
                                <td>10-20%</td>
                                <td>20-30%</td>
                                <td>30-40%</td>
                                <td>Above 40%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={ndviClassName}>
                    <SectionInfoHeader onClick={() => onInfoClick('ndviLegend')}/>
                    {legendCollapseTrigger}
                    <table>
                        <tbody>
                            <tr className='meta'><td colSpan={2}>BELOW AVERAGE</td></tr>
                            <tr><td><span className='marker cell1'>&nbsp;</span></td><td>&lt;50%</td></tr>
                            <tr><td><span className='marker cell2'>&nbsp;</span></td><td>50-70%</td></tr>
                            <tr><td><span className='marker cell3'>&nbsp;</span></td><td>70-80%</td></tr>
                            <tr><td><span className='marker cell4'>&nbsp;</span></td><td>80-90%</td></tr>
                            <tr><td><span className='marker cell5'>&nbsp;</span></td><td>90-110%</td></tr>
                            <tr><td><span className='marker cell6'>&nbsp;</span></td><td>110-120%</td></tr>
                            <tr><td><span className='marker cell7'>&nbsp;</span></td><td>120-130%</td></tr>
                            <tr><td><span className='marker cell8'>&nbsp;</span></td><td>130-150%</td></tr>
                            <tr><td><span className='marker cell9'>&nbsp;</span></td><td>&gt;150%</td></tr>
                            <tr className='meta'><td colSpan={2}>ABOVE AVERAGE</td></tr>
                        </tbody>
                    </table>
                </div>
                <div className={rainfallClassName}>
                    <SectionInfoHeader onClick={() => onInfoClick('rainfallLegend')}/>
                    {legendCollapseTrigger}
                    <table>
                        <tbody>
                            <tr className='meta'><td colSpan={2}>BELOW AVERAGE</td></tr>
                            <tr><td><span className='marker cell1'>&nbsp;</span></td><td>&lt;40%</td></tr>
                            <tr><td><span className='marker cell2'>&nbsp;</span></td><td>40-60%</td></tr>
                            <tr><td><span className='marker cell3'>&nbsp;</span></td><td>60-80%</td></tr>
                            <tr><td><span className='marker cell4'>&nbsp;</span></td><td>80-90%</td></tr>
                            <tr><td><span className='marker cell5'>&nbsp;</span></td><td>90-110%</td></tr>
                            <tr><td><span className='marker cell6'>&nbsp;</span></td><td>110-120%</td></tr>
                            <tr><td><span className='marker cell7'>&nbsp;</span></td><td>120-140%</td></tr>
                            <tr><td><span className='marker cell8'>&nbsp;</span></td><td>140-180%</td></tr>
                            <tr><td><span className='marker cell9'>&nbsp;</span></td><td>&gt;180%</td></tr>
                            <tr className='meta'><td colSpan={2}>ABOVE AVERAGE</td></tr>
                        </tbody>
                    </table>
                </div>
                <div className={ipcGlobalClassName}>
                    <SectionInfoHeader onClick={() => onInfoClick('ipcLegend')}/>
                    {legendCollapseTrigger}
                    <span className='header'>Number of people in IPC/CH Phase 3 or above (millions)</span>
                    <table>
                        <tbody>
                            <tr>
                                <td className='cell1 marker'>&nbsp;</td>
                                <td className='cell2 marker'>&nbsp;</td>
                                <td className='cell3 marker'>&nbsp;</td>
                                <td className='cell4 marker'>&nbsp;</td>
                                <td className='cell5 marker'>&nbsp;</td>
                                <td className='cell6 marker'>&nbsp;</td>
                                <td className='cell7 marker'>&nbsp;</td>
                                <td className='cell8 marker'>&nbsp;</td>
                            </tr>
                            <tr className='descriptions'>
                                <td>0-0.099</td>
                                <td>0.1-0.49</td>
                                <td>0.5-0.99</td>
                                <td>1.0-2.99</td>
                                <td>3.0-4.99</td>
                                <td>5.0-9.99</td>
                                <td>&gt;10</td>
                                <td className={'small'}>Not Analyzed</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={ipcCountryClassName}>
                    <SectionInfoHeader onClick={() => onInfoClick('ipcLegend')}/>
                    {legendCollapseTrigger}
                    <span className='title'>Acute food insecurity phase classification</span>
                    <span className='subtitle'>{info.ipcValidity !== undefined && info.ipcValidity}</span>
                    <table>
                        <tbody>
                            <tr><td className='left'><span className='marker phase1'>&nbsp;</span></td>
                                <td>Phase 1: Minimal</td></tr>
                            <tr><td className='left'><span className='marker phase2'>&nbsp;</span></td>
                                <td>Phase 2: Stressed</td></tr>
                            <tr><td className='left'><span className='marker phase3'>&nbsp;</span></td>
                                <td>Phase 3: Crisis</td></tr>
                            <tr><td className='left'><span className='marker phase4'>&nbsp;</span></td>
                                <td>Phase 4: Emergency</td></tr>
                            <tr><td className='left'><span className='marker phase5'>&nbsp;</span></td>
                                <td>Phase 5: Famine</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        }
    </>;
};

Legends.propTypes = {
    activeLayers: PropTypes.arrayOf(PropTypes.string).isRequired,
    countryPage: PropTypes.bool.isRequired,
    onInfoClick: PropTypes.func.isRequired,
    info: PropTypes.object.isRequired,
    onSetPopupShown: PropTypes.func,
    legendCollapsed: PropTypes.bool,
    onCollapseClick: PropTypes.func,
    conflictDisabled: PropTypes.bool,
    fcsAlertsDisabled: PropTypes.bool,
};

Legends.defaultProps = {
    onInfoClick: () => { },
    onSetPopupShown: () => { },
    onCollapseClick: () => { },
    legendCollapsed: false,
    conflictDisabled: false,
    fcsAlertsDisabled: false,
    info: {}
};

export default Legends;