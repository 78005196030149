import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, GeoJSON, Pane } from 'react-leaflet';
import moment from "moment";
import 'leaflet/dist/leaflet.css';
import PdcMarker from './PdcMarker';
import AcledScatter from './AcledScatter';
import Choropleth from "./Choropleth";
import { useGetData } from "./Api";
import TopBar from './TopBar';
import MapMenu from './MapMenu';
import AlertMarker from './AlertMarker';
import CountryPanes from './CountryPanes';
import DisclaimersPopup from "./DisclaimersPopup";
import './WorldMap.scss';
import CurrentDate from "./CurrentDate";
import Attributions from "./Attributions";
import L from 'leaflet';
import { useWindowHeight } from '@react-hook/window-size';
import constants, { mapSettings } from './constants';
import * as ReactGA from 'react-ga4';
import fixGeoData from './geoData';
import Tippy from '@tippy.js/react';
import { ossPublicFile, cdnPublicFile, apiUrl, legacyApiUrl, imgFile } from "./utils";

import MapZoomControl from './MapZoomControl';
import Legends from "./Legends";
import InfoOverlays from "./InfoOverlays";
import DisclaimerLinks from "./DisclaimerLinks";
import CountrySelector from "./CountrySelector";
import ProductSelector from "./ProductSelector";
import CountryNotes from './CountryNotes';
import VectorTileLayer from './VectorTileLayer';

import Adm1Labels from './Adm1Labels';
import LineChart from './LineChart';
import CrisisTriggerLayer from './CrisisTriggerLayer';

const conflictDisabled = process.env.REACT_APP_CONFLICT_DISABLED;

const apiEndpoint = process.env.NODE_ENV === 'development' ?
    process.env.REACT_APP_API_BACKEND_DEV :
    process.env.REACT_APP_API_BACKEND

const WorldMap = ({selectedRegion}) => {
    const windowHeight = useWindowHeight();
    const tallScreen = windowHeight > constants.tallScreenMinHeight;
    const smallScreen = windowHeight <= constants.mediumScreenMinHeight;
    const spinnerSize = tallScreen ? 250 : (smallScreen ? 180 : 200);
    const mapConfig = mapSettings['rbp'];

    // Fix via https://github.com/Leaflet/Leaflet/issues/3575#issuecomment-150544739
    const originalInitTile = L.GridLayer.prototype._initTile;
    L.GridLayer.include({
        _initTile: function (tile) {
            originalInitTile.call(this, tile);
            const tileSize = this.getTileSize();
            if (this.options.id !== undefined && this.options.id === 'proteus') {
                return;
            }
            tile.style.width = `${tileSize.x + 1}px`;
            tile.style.height = `${tileSize.y + 1}px`;
        }
    });

    const mapRef = useRef(null);
    const countryRef = useRef({});
    const countrySelectorRef = useRef(null);
    const [viewport, setViewport] = useState({center: [45, -100], zoom: 6});
    const [activeLayer, setActiveLayer] = useState({ base: 'fcs', scatter: null });
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [popupShown, setPopupShown] = useState(null);
    const [activeInfoOverlay, setActiveInfoOverlay] = useState(null);
    const [ipcValidity, setIpcValidity] = useState(null);
    const [collapsedLegends, setCollapsedLegends]= useState(true);
    const [showCountriesInCrisis, setShowCountriesInCrisis] = useState(true)
    const [showAlerts, setShowAlerts] = useState(true)
    const resetView = ({ preserveTab } = { preserveTab: false }) => {
        setSelectedCountry(null);
        mapRef.current.fitBounds(mapConfig.bounds);
        setActiveInfoOverlay(null);
        if (!preserveTab) {
            setActiveLayer({ base: 'fcs', scatter: null });
            setCollapsedLegends(false);
        }
        setPopupShown(null);
    };

    useEffect(() => {
        const gaTracker = (selectedRegion === 'global' ? 'UA-150293810-1' : 'G-7HP3PNWFNB');

        ReactGA.default.initialize(gaTracker)
    }, [showAlerts, showCountriesInCrisis ]);
    useEffect(() => {
        const countryName = selectedCountry === null ? 'global' : selectedCountry.name.replace(/ /g, '_')
            .replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase();
        let pageView =
            `${countryName}/${activeLayer.base}${activeLayer.scatter !== null ? ('_' + activeLayer.scatter) : ''}`
        if (selectedRegion !== 'global') {
            pageView = `${selectedRegion}/${pageView}`
        }

        ReactGA.default.send({ hitType: "pageview", page: pageView, title: "Page View" });

        const map = mapRef.current;
        if (map !== null) {
            map.options.maxZoom = (selectedCountry === null ? 8 : 18);
        }
        if(selectedCountry !== null) {
            setCollapsedLegends(true)
        } else {
            setCollapsedLegends(false)
        }

    }, [activeLayer, selectedCountry , selectedRegion, mapRef]);

    const [disputedBoundariesData, refreshDisputedBoundariesData] =
        useGetData({
            url: cdnPublicFile('hungermap/adm0_disputed_areas_lowres.json'),
            defaultValue: { features: [] }
        });
    const [adm1LabelData, refreshAdm1LabelData] =
        useGetData({
            url: cdnPublicFile('hungermap/adm1_labels.geojson'),
            defaultValue: { features: [] }
        });

    const [adm0Data, refreshAdm0Data] =
        useGetData({ url: `${apiEndpoint}/global`, defaultValue: { features: [] } });
    const aggregatePopCombined = Math.round(adm0Data.features.reduce((total, feature) => total + feature.properties.food_security_numbers.combined, 0) / 1000000 * 10) / 10;
    const triggerCountries =  adm0Data.features.filter(feature => feature.properties.trigger === true);
    const triggerCountriesCollection = {
        type: 'FeatureCollection',
        features: triggerCountries,
    };

    // Still needed to get import dependency
    const [adm1DataLegacy, refreshAdm1DataLegacy] =
        useGetData({
            url: (selectedCountry == null ? null :
                legacyApiUrl(`adm0/${selectedCountry.id}/countryData.json`))
        });
    const [adm1Data, refreshAdm1Data] =
        useGetData({
            url: (selectedCountry == null ? null :
                `${apiEndpoint}/country?adm0=${selectedCountry.id}`)
        });

    const [countryIso3Data, refreshCountryIso3Data] =
        useGetData({
            url: (selectedCountry == null ? null :
                legacyApiUrl(`iso3/${selectedCountry.iso3}/countryIso3Data.json`))
        });
    const [pdcData, refreshPcdData] =
        useGetData({ url: legacyApiUrl('pdc.json'), defaultValue: [] });
    const [acledData, refreshAcledData] =
        useGetData({ url: legacyApiUrl('acled.geojson'), defaultValue: { features: [] } });

    const refreshData = () => {
        refreshDisputedBoundariesData();
        refreshAdm1LabelData();
        refreshAdm1Data();
        refreshAdm1DataLegacy();
        refreshCountryIso3Data();
        refreshPcdData();
        refreshAcledData();
        refreshAdm0Data();
    };

    const switchLayer = layerName => {
        let { base, scatter } = activeLayer;
        if (['fcs', 'rainfall', 'ndvi', 'ipc'].indexOf(layerName) > -1) {
            base = layerName;
        }
        if (['acled', 'pdc'].indexOf(layerName) > -1) {
            scatter = (scatter === layerName ? null : layerName);
        }

        setActiveLayer({ base, scatter });
        setCollapsedLegends(false);
    };

    const countriesHighHunger = adm0Data.features.length === 0 ? '' : adm0Data.features.filter(({ properties: { fcs } }) =>
        fcs !== null && fcs >= 0.4).length;
    const [popPower, popSuffix] = selectedRegion === 'global' ? [9, 'B'] : [6, 'M'];
    const fcsPopulationTotal = adm0Data.features.length === 0 ? '' : Math.round(adm0Data.features
        .filter(({ properties: { fcs_people_total } }) => fcs_people_total !== null)
        .map(({ properties: { fcs_people_total } }) => fcs_people_total)
        .reduce((acc, cur) => acc + cur) / Math.pow(10, popPower) * 100) / 100 + popSuffix;

        let iso3s = []

        adm0Data.features.map(feature => {
            const s = feature.properties.iso3
            iso3s.push(s.toString())
        })

        const rbpCountries = adm0Data.features.filter(feature => iso3s.includes(feature.properties.iso3));

        const rbpCountryCollection = {
            "type": "FeatureCollection",
            "features": rbpCountries
        };

    const boundariesStyle = {
        color: 'RGBA(255, 255, 255, 0.6)',
        weight: 1.3,
        fillOpacity: 0,
        interactive: false
    };
    const disputedBoundariesStyle = Object.assign({}, boundariesStyle, { dashArray: [5, 5] });
    const fcsStyle = feature => ({
        color: 'none',
        fillOpacity: 0,
        fillColor: fcsFill(feature.properties.fcs)
    });
    const fcsHoverStyle = {
         fillColor: '#fff', fillOpacity: 0.2
    };
    const fcsAdm1Style = feature => ({
        color: '#fff',
        weight: 1,
        fillOpacity: 0.75,
        fillColor: fcsFill((feature.properties.fcs !== null || feature.properties.fcs === 0)  ? feature.properties.fcs.prevalence : null)
    });
    const fcsFill = fcs => {
        if (fcs === null) return 'none';
        if (fcs <= 0.05) return '#29563A';
        if (fcs <= 0.1) return '#73B358';
        if (fcs <= 0.2) return '#CBCC58';
        if (fcs <= 0.3) return '#d5a137';
        if (fcs <= 0.4) return '#EB5A26';
        return '#D3130C';
    };

    const pdcMarkers = pdcData.map(datum => <PdcMarker pdcEvent={datum} key={`pdc_marker_${datum.Id}`}
        backgroundMarkersPane='backgroundMarkers' markersPane='markers' />);
    if (conflictDisabled == 'true') {
        adm0Data.features.forEach(({properties: {alerts}}) => {
           if (alerts !== null && alerts.conflict) {
               alerts.conflict = false;
           } else {
            alerts.conflict = true;
        }    
        });
    } 
    const fcsAlertsDisabled = true;

    let alertMarkers = [];

    if(showAlerts == true) {
        alertMarkers = adm0Data.features
        .map((feature) => <>
            {feature.properties.alerts.economic && <AlertMarker center={feature.properties.centroid} type={'economic'} alertsGroup={feature.properties.alerts} />}
            {conflictDisabled == 'false' && feature.properties.alerts.conflict && <AlertMarker center={feature.properties.centroid} type={'conflict'} alertsGroup={feature.properties.alerts} />}
            {feature.properties.alerts.climate && <AlertMarker center={feature.properties.centroid} type={'climate'} alertsGroup={feature.properties.alerts} />}
            {feature.properties.alerts.hazard && <AlertMarker center={feature.properties.centroid} type={'hazard'} alertsGroup={feature.properties.alerts} />}
        </>);
    } else {
        if(showAlerts == false) {
            alertMarkers = []
        }
    }

    const numberFormat = new Intl.NumberFormat('en-US');

    const roundFormat = n => {
        if (n < 1e3) return numberFormat.format(n);
        if (n < 1e6) return numberFormat.format(+(n / 1e3).toFixed(1)) + "K";
        return numberFormat.format(+(n / 1e6).toFixed(1)) + "M";
    };

    let countryDataType = null;
    if(adm1Data && adm1Data.features.length > 0){
        const adm1Types = adm1Data.features.filter(x=> x.properties.fcs)
            .map(x => x.properties.fcs.dataType.toUpperCase());
        countryDataType = adm1Types.indexOf('ACTUAL DATA')> -1 ? 'ACTUAL': 'PREDICTED';
    }

    const hasInsightReport = selectedCountry === null ? false :
        adm0Data.reports.find(report => report.iso3 === selectedCountry.iso3) !== undefined;

    const insightReport = hasInsightReport ?
        adm0Data.reports.find(report => report.iso3 === selectedCountry.iso3).url : null;
        
    return <>
        <MapContainer dragging={true} animate={false} bounds={mapConfig.bounds} onViewportChanged={setViewport} minZoom={4} zoomSnap={0.2} maxZoom={12}
            style={{ display: activeLayer.base === 'undernourishment' ? 'none' : 'block' }}
            whenReady={({ target: map }) => mapRef.current = map}
            attributionControl={false} zoomControl={false} maxBounds={mapConfig.bounds.pad(0.5)}
            maxBoundsViscosity={0.5}>

            <VectorTileLayer url={cdnPublicFile('vector-tiles/styles/v1/hungermap-osm.json')} />

            <CrisisTriggerLayer data={adm0Data} showCountriesInCrisis={showCountriesInCrisis} />

            {activeLayer.base === 'rainfall' && <TileLayer url={apiUrl('tiles/rainfall/{z}/{x}/{y}')}
                key={`rainfall_${viewport.zoom}`} className={'rainfallLayer'} maxNativeZoom={7} />
            }
            {activeLayer.base === 'ndvi' && <TileLayer url={apiUrl('tiles/ndvi/{z}/{x}/{y}')}
                key={`ndvi_${viewport.zoom}`} className={'ndviLayer'} maxNativeZoom={7} />}
            {activeLayer.base === 'fcs' && !selectedCountry && <>
                <TileLayer url={ossPublicFile('proteus_tiles_IDB/{z}/{x}/{y}.png')} id='proteus' tms={true}
                    maxNativeZoom={3} />
            </>}
            {['fcs', 'rainfall', 'ndvi'].indexOf(activeLayer.base) > -1 && !selectedCountry && <Choropleth
                data={{features: rbpCountryCollection.features}}
                key={`hover_${rbpCountryCollection.features === undefined ? 'u' : rbpCountryCollection.features.length}_${activeLayer.base}`}
                style={fcsStyle}
                hoverStyle={fcsHoverStyle}
                handleClick={(feature, bounds) => {
                    setSelectedCountry({
                        id: feature.properties.adm0_id,
                        name: feature.properties.adm0_name,
                        iso3: feature.properties.iso3,
                    });
                    setActiveInfoOverlay(null);
                    mapRef.current.fitBounds(
                        bounds.pad(0.2));    
                }}
                onEachFeature={(feature, layer) => {
                    fixGeoData(feature, layer);
                    countryRef.current[feature.properties.adm0_id] = layer;
                    layer.bindTooltip(feature.properties.adm0_name, { sticky: true, className: 'countryTooltip' })
                }}
                onFeatureMouseOver={() => countrySelectorRef.current.blur()}
            />}
            {activeLayer.base === 'fcs' && selectedCountry && adm1Data && 
                <Choropleth data={adm1Data} key={`adm1_fcs_${selectedCountry.id}_${viewport.zoom}`}
                    style={fcsAdm1Style}
                    tooltip={{className: `fcsTooltip`, render: (feature) => {
                        const { Name, fcs, rcsi, combined } = feature.properties;
    

                        if (fcs !== null && rcsi !== null && combined !== null) {
                            const combinedGraphData = combined === null ? { data: [] } : {
                                data: combined.chart.map(({ date, prevalence }) => ({
                                    x: new Date(date),
                                    y: prevalence,
                                })),
                                unit: 'M',
                                color: '#E08004'
                            };
                            const dataText = (value, formatData, dataType) => <>
                                <span className="value">{value === null ? 'No Data' : formatData(value)}{' '}</span>
                                </>
                            const infoText = (data, description, dataType) => <>
                                {dataText(data.prevalence, (v) => ((Math.round(v * 100)) + '%'), dataType)}{' '}
                                ({dataText(data.people, roundFormat, dataType)})
                                with {description}
                                </>

                            return <div className={`content ${fcs.dataType.toLowerCase()=='prediction'? 'wide': ''}`}>
                                <span className='geoname'>{Name}</span><br/>
                                <p>{infoText(fcs, "insufficient food consumption", fcs.dataType)}</p>
                                <p>{infoText(rcsi, "crisis or above crisis food-based coping", fcs.dataType)}</p>
                                <p>{infoText(combined, "risk of food insecurity", combined.dataType)}</p>
                                <p>({fcs.dataType.toLowerCase()})</p>
                                {
                                    fcs.dataType.toLowerCase()==='actual' ?
                                        <>
                                            <p className={'chartTitle'}>Trend of the number of people at risk of food insecurity</p>
                                            <div className='chartBox'>
                                                {combinedGraphData.data.filter(({y}) => y !== null).length > 1 ?
                                                    <LineChart series={[combinedGraphData]} buffer={0.5} height={120} allowMargin={false} selfRound/> :
                                                    <span className='graphNoData'>No Data</span>}
                                            </div>
                                        </>
                                    : null
                                }
                            </div>
                        }
                    }}} />}

            {activeLayer.scatter === 'acled' && <AcledScatter data={acledData} pane='markers'
                key={`acled_${acledData.features === undefined ? 'u' : acledData.features.length}`} />}
            {activeLayer.base === 'fcs' && activeLayer.scatter === null && !selectedCountry && alertMarkers}
            <Pane>
                {selectedCountry !== null && <>
                    <Adm1Labels features={adm1LabelData.features}
                        iso3={selectedCountry.iso3}
                        key={`dbd${adm1LabelData.features.length}_${activeLayer.base}_${viewport.zoom}`} />
            </>}
            {activeLayer.scatter === 'pdc' && pdcMarkers}
            </Pane>
            <Pane name='backgroundMarkers' />
            <Pane name='markers' />
            <MapZoomControl global={!(adm1Data !== null && countryIso3Data !== null)} />
        </MapContainer>
        <TopBar onClick={resetView} selectedCountry={selectedCountry} selectedRegion={mapConfig}
                dataType={countryDataType}/>
        <MapMenu activeTabs={[activeLayer.base, activeLayer.scatter].filter(t => t !== null)}
            onSelect={(layer) => { setActiveInfoOverlay(null); switchLayer(layer) }}
            hasUndernourishment={selectedCountry === null}
            conflictDisabled={conflictDisabled}
            hasIpc={selectedCountry === null || selectedCountry.ipcAreasAnalysis !== null} />
        <div className={`numberOverlay peopleOverlay ${activeLayer.base === 'fcs' && fcsPopulationTotal !== '' &&
            !selectedCountry ? 'active' : ''}`}>
            {triggerCountriesCollection.features.length >= 1 &&
            <div>
                <div className='background'>
                    <img src={imgFile('green_left_circle_static.png')} style={{ width: spinnerSize, height: spinnerSize }}
                        key={`spinner_green_${spinnerSize}`} className='blueSpinner' alt={''} />
                </div>
                <div className='content'>
                    <span className='value'>{triggerCountriesCollection.features.length}</span>
                    {triggerCountriesCollection.features.length == 1 ?
                        <span>country<br/>
                        in crisis
                        </span>
                        :
                        <span>countries<br/>
                        in crisis
                        </span>
                    }
                </div>
            </div>
            }
        </div>
        <div className={`numberOverlay countriesOverlay ${activeLayer.base === 'fcs' && countriesHighHunger !== '' &&
         !selectedCountry && aggregatePopCombined > 0 ? 'active' : ''}`}>
                <div className='background'>
                    <img src={imgFile('blue_left_circle_static.png')} style={{ width: spinnerSize, height: spinnerSize }}
                        key={`spinner_blue_${spinnerSize}`} className='greenSpinner' alt={''} />
                </div>
                <div className='content'>
                    <span className='value'>{aggregatePopCombined}M</span>
                        people at<br />
                        risk of food<br />
                        insecurity
                </div>  
        </div>
        <CountryPanes  data={adm1Data !== null && countryIso3Data !== null && adm1DataLegacy !== null ? {
            insightReport,
            ...adm1Data,
            ...adm1DataLegacy,
            ...countryIso3Data
        } : null} onInfoClick={setActiveInfoOverlay} />
        <div className={`backButton ${adm1Data !== null && countryIso3Data !== null ? 'active' : ''}`}
            onClick={() => resetView({ preserveTab: true })}>
            <span>Back to {selectedRegion==='global'? 'Global': 'Regional'}</span>
        </div>
        {selectedRegion !== 'global' &&
            <div className={`backButton goto ${adm1Data === null && countryIso3Data === null ? 'active' : ''}`}
                 onClick={() => {window.location = '/';}}>
                <span>Go to Global</span>
                <span>{setCollapsedLegends(true)}</span>
            </div>
        }
        <DisclaimerLinks onHomeClick={resetView}
            onClick={(popup) => { setActiveInfoOverlay(null); setPopupShown(popup) }}
        />
        <DisclaimersPopup activeSection={popupShown} onClose={() => setPopupShown(null)}
            onChangeContent={(idpopup) => { setPopupShown(idpopup) }}
        />
        <div className={`dropdownsContainer ${['fcs', 'rainfall', 'ndvi'].indexOf(activeLayer.base) > -1 && !selectedCountry &&
                rbpCountryCollection.features.length > 0 ? 'active' : '' }`}>
            <CountrySelector
                active={['fcs', 'rainfall', 'ndvi'].indexOf(activeLayer.base) > -1 && !selectedCountry &&
                adm0Data.features.length > 0}
                options={rbpCountryCollection.features
                    .filter(f => f.properties.fcs !== null && f.properties.interactive)
                    .map(f => ({ value: f.properties, label: f.properties.adm0_name }))
                    .sort((a, b) => a.label < b.label ? -1 : 1)}
                onChange={(country) => {
                    mapRef.current.options.maxZoom = 8;
                    mapRef.current.options.zoomSnap = 0.2;
                    mapRef.current.options.bounds = mapConfig.bounds;
                    mapRef.current.options.maxBounds = mapConfig.bounds.pad(0.5)
                    mapRef.current.options.maxBoundsViscosity=0.5;
                    setSelectedCountry({
                        id: country.value.adm0_id,
                        name: country.value.adm0_name,
                        iso3: country.value.iso3,
                        ipcAreasAnalysis: country.value.ipcAreasAnalysis
                    });
                    setActiveInfoOverlay(null);
                    mapRef.current.fitBounds(countryRef.current[country.value.adm0_id].getBounds().pad(0.2));
                }}
                onFocusChanged={(adm0Id) => {
                    for (let currentAdm0 in countryRef.current) {
                        const country = countryRef.current[currentAdm0];
                        country.setStyle(parseInt(currentAdm0, 10) === adm0Id ? fcsHoverStyle : fcsStyle(country.feature));
                    }
                }} ref={countrySelectorRef}
            />
            <ProductSelector
                countryInsights = {adm0Data.reports}
                selectedRegion = {selectedRegion}
                configs = {{region: 'rbp', mapConfig: mapConfig}}
                onInfoClick={setActiveInfoOverlay}
            />
        </div>
        <CurrentDate refresh={refreshData} refreshIntervalSeconds={60 * 60} />
        <Tippy content="Click to receive the latest updates, data and analyses"
               className={`subscribeTooltip ${selectedCountry !== null ? 'left' : 'bottom'}`}
               placement={selectedCountry !== null ? 'left' : 'bottom-start'}
               offset={tallScreen ? [0, 10] : smallScreen ? [0, 3] : [0, 5]}>
            <div className="subscribe">
                <a href="http://eepurl.com/g9YxTv" target="_blank" rel="noopener noreferrer">
                    <img className="button" src={imgFile('subscribe_button.png')}/>
                </a>
            </div>
        </Tippy>
        <div>
        <Legends activeLayers={[activeLayer.base, activeLayer.scatter].filter(t => t !== null)}
            countryPage={adm1Data !== null && countryIso3Data !== null}
            onInfoClick={setActiveInfoOverlay} info={{ ipcValidity: ipcValidity }}
            onSetPopupShown={setPopupShown}
            legendCollapsed={collapsedLegends}
            conflictDisabled={conflictDisabled}
            fcsAlertsDisabled = {fcsAlertsDisabled}
            onCollapseClick={ ()=>{ setCollapsedLegends(!collapsedLegends); setActiveInfoOverlay(null) }}
            countriesInCrisis={triggerCountriesCollection}
            showCountriesInCrisis={showCountriesInCrisis} 
            showAlerts={showAlerts}
            setShowCountriesInCrisis={setShowCountriesInCrisis} 
            setShowAlerts={setShowAlerts}
            />
        </div>

        <Attributions activeLayers={[activeLayer.base, activeLayer.scatter].filter(t => t !== null)}
            countryPage={adm1Data !== null && countryIso3Data !== null} />
            <InfoOverlays activeLayers={[activeLayer.base, activeLayer.scatter].filter(t => t !== null)}
            activeOverlay={activeInfoOverlay} onClose={() => { setActiveInfoOverlay(null) }}
            countryPage={adm1Data !== null && countryIso3Data !== null}
            info={{
                populationSource: adm1Data !== null ? adm1Data.populationSource : null,
                currencyExchangeSource: countryIso3Data !== null ?
                    countryIso3Data.currencyExchangeGraph.source : null,
                currencyExchangeUpdated: countryIso3Data !== null ?
                    countryIso3Data.currencyExchangeGraph.updated : null,
                nutritionSource: countryIso3Data !== null ?
                    countryIso3Data.nutrition.source : null
            }} />
    </>;
};

WorldMap.propTypes = {
    selectedRegions: PropTypes.string.isRequired
}
WorldMap.defaultProps = {
    selectedRegions: 'global'
}

export default WorldMap;
