import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMap } from 'react-leaflet';
import { GeoJSON } from 'react-leaflet/GeoJSON'
import 'leaflet/dist/leaflet.css';
import { createRoot } from 'react-dom/client';
import './WorldMap.scss';


const Choropleth = ({data, style, hoverStyle, handleClick, onEachFeature, onFeatureMouseOver,tooltip}) => {
    const geoJsonRef = useRef();
    const { map } = useMap();

    // Inspiration: https://stackoverflow.com/questions/66593794/display-new-geojson-data-for-geojson-class-on-click-of-button
    const onEachFeature2 = (feature, layer) => {
        layer.on({
            mouseover: (e) => {
                e.target.setStyle(hoverStyle);
                if(onFeatureMouseOver) {
                    onFeatureMouseOver()
                }
            },
            mouseout: (e) => {
                geoJsonRef.current.resetStyle(e.target); 
            },
            click: (e) => {
                handleClick(e.target.feature, e.target.getBounds(), map)
            }
        });

        if(onEachFeature){
            onEachFeature(feature, layer)
        }

        if(tooltip) {
            const content = tooltip.render(feature);
            const div = document.createElement('div');
            const reactTooltipContent = content

            const root = createRoot(div);
            root.render(reactTooltipContent);

            layer.bindTooltip(div, { sticky: true, className: tooltip.className});
        } else {
            layer.bindTooltip(feature.properties.adm0_name, { sticky: true, className: 'countryTooltip' })
        }
       
    };

    useEffect(() => {
        if (geoJsonRef.current){
          geoJsonRef.current.clearLayers()
          geoJsonRef.current.addData(data)
        }
    }, [geoJsonRef, data, tooltip, handleClick])

   
    return <>
            <GeoJSON
                ref={geoJsonRef}
                style={style}
                data={data}
                onEachFeature={onEachFeature2}
            />
    </>;
};

Choropleth.propTypes = {
    data: PropTypes.object.isRequired,
    style: PropTypes.func,
    hoverStyle: PropTypes.func,
    handleClick: PropTypes.func,
    onEachFeature: PropTypes.func,
    onFeatureMouseOver: PropTypes.func,
    tooltip: PropTypes.shape({
        className: PropTypes.string,
        render: PropTypes.func
    })
};
Choropleth.defaultProps = {
    style: () => ({}),
    hoverStyle: null,
    handleClick: () => {},
    onFeatureMouseOver: () => {}
};

export default Choropleth;