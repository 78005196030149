const ossPublicFile = path => `https://static.hungermapdata.org/${path}`;

const cdnPublicFile = path => `https://cdn.hungermapdata.org/${path}`;

const imgFile = filename => ossPublicFile(`hungermap/img/${filename}`);

const legacyApiUrl = (path, version='.LATEST') => `https://5763353767114258.eu-central-1.fc.aliyuncs.com/2016-08-15/proxy/` +
   `wfp-data-api${version}/map-data/${path}`;

    
const apiUrl = path => `https://api.hungermapdata.org/${path}`;

const lastNotNull = arr => arr.filter(d => d !== null).slice(-1)[0] || 0;

export {ossPublicFile, cdnPublicFile, apiUrl, legacyApiUrl, imgFile, lastNotNull};