import React from 'react';
import PropTypes from 'prop-types';
import './TopBar.scss';
import {imgFile} from "./utils";

const TopBar = ({onClick, selectedCountry, selectedRegion, dataType}) => {

    return <div className={'TopBar'}>
        <div className='content'>
            <div className='left'>
                <img src={imgFile('wfp_logo.svg')}
                     alt="" className={'logo link'} onClick={onClick}/>
            </div>
            {/* <div className={'center'}>
                <span className='link' style={{ width: '390px', fontWeight: 'bold'}} onClick={onClick}>CRISIS MONITORING DASHBOARD</span>
            </div> */}

            <div>
                { selectedCountry !== null ? 
                <div>
                    <div className={'center'}>
                        <span className='link title-country' onClick={onClick}>CRISIS MONITORING DASHBOARD</span>
                    </div>
                </div> 
                :
                <div>
                <div className={'center'}>
                    <span className='link title' onClick={onClick}>CRISIS MONITORING DASHBOARD</span>
                </div>
                </div>
                }
                <div className={`countryName ${selectedCountry !== null ? 'active' : ''}`}>
                    {/* {selectedCountry && <>{selectedCountry.name}<sup className={'dataType'}>{dataType}</sup></> } */}
                    {selectedCountry && <>{selectedCountry.name}</> }
                </div>
            </div>
        </div>
    </div>
};

TopBar.propTypes = {
    onClick: PropTypes.func,
    selectedCountry: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        iso3: PropTypes.string.isRequired
    }),
    dataType: PropTypes.string
};
TopBar.defaultProps = {
    onClick: () => {},
    dataType: null
};

export default TopBar;
