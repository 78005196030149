import React from 'react';
import './App.css';
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';
import WorldMap from './WorldMap';
import {BrowserRouter as Router, Switch, Route, Routes, useParams} from "react-router-dom";
import {mapSettings} from "./constants";

const WorldMapWrapper = () => {
    let { regionLink } = useParams();

    const [regionEntry] = Object.entries(mapSettings).filter(entry => entry[1].link === regionLink);

    if (regionEntry !== undefined) {
        const region = regionEntry[0];
        return <WorldMap selectedRegion={region} />
    }
    return null;
}

function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={  <WorldMap selectedRegion="global" />} />
                <Route exact path="/:regionLink"  element={<WorldMapWrapper/>} />
            </Routes>
        </Router>
    );
}

export default App;