import React from 'react';
import PropTypes from 'prop-types';
import './InfoOverlays.scss';
import classNames from 'classnames';

const InfoOverlays = ({activeLayers, activeOverlay, onClose, countryPage, info}) => {
    const header = <div className='header'>
        <div className='closeButton' onClick={onClose}>&nbsp;</div>
    </div>;

    return <>
        {activeOverlay === 'pdcLegend' && <div className={classNames('pdcLegend', 'infoOverlay', {
            countryPage, withFcs: activeLayers.indexOf('fcs') > -1, withIpc: activeLayers.indexOf('ipc') > -1})}>
            {header}
            <div className='content'>
                <p>
                    <b>Current hazards information:</b> Active Volcanoes; Active/Forecast Wind Radii (39, 58, 74);
                    Previous, Current and Forecast Storm Positions; 3- and 5-day Potential Track Area of Storms; Recent
                    Earthquakes; MODIS Hotspots; Tsunami Travel Time; GLIDE Events; H1N1 Affected Countries; Country
                    Boundaries and Labels; Global Shaded Relief; Global Population Density; and PDC integrated hazards.
                </p>
                <p>
                    <b>Data source:</b> Pacific Disaster Centre (PDC)- Active Hazards Map
                    Service <a href='https://www.pdc.org' target='_blank' rel='noopener noreferrer'>www.pdc.org</a>
                </p>
                <p>
                    <b>Updated:</b> Real-time (every hour)
                </p>
            </div>
        </div>}
        {activeOverlay === 'acledLegend' && <div className={classNames('acledLegend', 'infoOverlay', {
            countryPage, withFcs: activeLayers.indexOf('fcs') > -1, withIpc: activeLayers.indexOf('ipc') > -1})}>
            {header}
            <div className='content'>
                <p>
                    All reported violence and conflicts across Africa, the Middle East, South and South East Asia,
                    Eastern and Southeastern Europe and the Balkans.
                </p>
                <p>
                    <b>Data source:</b> Armed Conflict Location & Event Data Project (ACLED)<br/>
                    <a href='https://acleddata.com' target='_blank' rel='noopener noreferrer'>acleddata.com</a>
                </p>
                <p>
                    <b>Updated:</b> Daily, displaying data from the last 30 days
                </p>
            </div>
        </div>}
        {activeOverlay === 'fcsLegend' && <div className={classNames('fcsLegend', 'infoOverlay', {countryPage})}>
            {header}
            <div className='content'>
                <p>
                    <b>People with insufficient food consumption</b> refers to those with poor or borderline food
                    consumption, according to the Food Consumption Score (FCS).
                </p>
                <p>
                    The <b>Food Consumption Score (FCS)</b> is a proxy of household's food access and a core
                    WFP indicator used to classify households into different groups based on the adequacy of
                    the foods consumed in the week prior to being surveyed. FCS is the most commonly used food
                    security indicator by WFP and partners. This indicator is a composite score based on household's
                    dietary diversity, food frequency, and relative nutritional importance of different food groups.
                    The FCS is calculated using the frequency of consumption of eight food groups by a household during
                    the 7 days before the survey using standardized weights for each of the food groups reflecting its
                    respective nutrient density,and then classifies households as having ‘poor’, ‘borderline’ or ‘acceptable’
                    food consumption.
                </p>
                <p>
                    <b>Poor food consumption:</b> Typically refers to households that are not consuming staples and
                    vegetables every day and never or very seldom consume protein-rich food such as meat and dairy (FCS
                    of less than 28).
                </p>
                <p>
                    <b>Borderline food consumption:</b> Typically refers to households that are consuming staples and
                    vegetables every day, accompanied by oil and pulses a few times a week (FCS of less than 42).
                </p>
                <p>
                    <b>Acceptable food consumption:</b> Typically refers to households that are consuming staples and
                    vegetables every day, frequently accompanied by oil and pulses, and occasionally meat, fish and
                    dairy (FCS greater than 42).
                </p>
                <p>
                    <b>Data source:</b> World Food Programme - (i) near real-time food security monitoring systems
                    (where available), based on mobile Vulnerability Analysis and Mapping (mVAM) surveys; (ii)
                    HungerMapLIVE predictive model{countryPage ? '' :
                        (<>; or <a href='https://www.sciencedirect.com/science/article/abs/pii/S0305750X19303572' target='_blank' rel='noopener noreferrer'>Proteus index</a></>)}.
                </p>
                <p>
                    <b>Updated:</b> Daily{!countryPage && ' (low and lower-middle income countries), Annually ' +
                    '(upper-middle and high income countries)'}.
                </p>
            </div>
        </div>}
        {activeOverlay === 'ndviLegend' && <div className={classNames('ndviLegend', 'infoOverlay', {countryPage})}>
            {header}
            <div className='content'>
                <p>
                    The <b>Normalized Difference Vegetation Index (NDVI)</b> layer shows the recent vegetation
                    development compared to the average. Green shades show areas where vegetation cover is above
                    average, whilst orange and brown shades identify areas where vegetation cover is below normal.
                    Users can evaluate whether vegetation cover is becoming sparser (darkening greens or lightening
                    oranges) or denser (darkening oranges and lightening greens). Values between 90% and 110% are
                    considered as being within the range of normal variability.
                </p>
                <p>
                    <b>Data source:</b> MODIS platforms Terra and Aqua. MODIS NDVI CMG data product retrieved from
                    Earthdata Search, courtesy of NASA EOSDIS Land Processes Distributed Active Archive Center
                    (LP DAAC), USGS/Earth Resources Observation and Science (EROS) Center and Sioux Falls, South
                    Dakota, USA.
                </p>
                <p>
                    <b>Updated:</b> Every 8 days
                </p>
            </div>
        </div>}
        {activeOverlay === 'rainfallLegend' && <div className={classNames('rainfallLegend', 'infoOverlay',
                {countryPage})}>
            {header}
            <div className='content'>
                <p>
                    The <b>1-month rainfall anomaly layer</b> shows cumulative seasonal rainfall as a percentage of the
                    20-year average every ten days. Values below 100% indicate drier than average conditions, above 100%
                    indicate wetter than average conditions. Variations between 90% and 110% are considered as having an
                    inconsequential impact for crops or pasture and these are represented in white. Otherwise, brown
                    shades indicate below-average rainfall and blue shades indicate above-average seasonal rainfall.
                    Users can evaluate whether the season is becoming drier than average (blue shades turning darker
                    or brown shades getting lighter) or wetter than average (brown shades turning darker or blue shades
                    getting lighter).
                </p>
                <p>
                    <b>Data source:</b> CHIRPS rainfall estimates, Climate Hazards Group, University of California at
                    Santa Barbara, processed by WFP-VAM
                </p>
                <p>
                    <b>Updated:</b> Every 10 days
                </p>
            </div>
        </div>}
        {activeOverlay === 'ipcLegend' && <div className={classNames('ipcLegend', 'infoOverlay', {countryPage})}>
            {header}
            <div className='content'>
                <p>
                    Developed by a global partnership, the <b>Integrated Food Security Phase Classification (IPC) /
                    Cadre Harmonisé (CH)</b> is a set of tools and procedures to classify food insecurity. It classifies
                    the populations in five different phases according to the severity of the food insecurity and
                    malnutrition situation: Minimal, Stressed, Criss, Emergency, and Catastrophe/Famine.
                </p>
                <p>
                    <b>Data source:</b> Integrated Food Security Phase Classification (IPC) / Cadre Harmonisé
                    (CH) <a href='http://www.ipcinfo.org' target='_blank' rel='noopener noreferrer'>www.ipcinfo.org</a>
                </p>
                <p>
                    <b>Updated:</b> N/A
                </p>
            </div>
        </div>}
        {activeOverlay === 'foodSecuritySection' && <div className={classNames('foodSecuritySection', 'infoOverlay')}>
            {header}
            <div className='content'>
                <h1>Population</h1>
                <p>
                    Total population counts all residents, regardless of legal status or citizenship (the values shown
                    are mid-year estimates)<br/>
                    <b>Data source:</b> {info.populationSource !== null && info.populationSource}<br/>
                    <b>Updated:</b> N/A
                </p>
                <h1>Food Consumption Score (FCS)</h1>
                <p>
                    is a proxy of household's food access and a core WFP indicator used to classify households into
                    different groups based on the adequacy of the foods consumed in the week prior to being surveyed.
                    FCS is the most commonly used food security indicator by WFP and partners. This indicator is a
                    composite score based on household's dietary diversity, food frequency, and relative nutritional
                    importance of different food groups. The FCS is calculated using the frequency of consumption of eight
                    food groups by a household during the 7 days before the survey using standardized weights for each
                    of the food groups reflecting its respective nutrient density, and then classifies households as
                    having ‘poor’, ‘borderline’ or ‘acceptable’ food consumption.<br/>
                    <b>Poor food consumption:</b> Typically refers to households that are not consuming staples and
                    vegetables every day and never or very seldom consume protein-rich food such as meat and dairy
                    (FCS of less than 28).<br/>
                    <b>Borderline food consumption:</b> Typically refers to households that are consuming staples and
                    vegetables every day, accompanied by oil and pulses a few times a week (FCS of less than 42).<br/>
                    <b>Acceptable food consumption:</b> Typically refers to households that are consuming staples and
                    vegetables every day, frequently accompanied by oil and pulses, and occasionally meat, fish and
                    dairy (FCS greater than 42).
                </p>
                <p>
                    People with insufficient food consumption refers to those with poor or borderline food consumption.
                </p>
                <p>
                    <b>Data source:</b> World Food Programme - (i) representative face-to-face household surveys, for
                    example Comprehensive Food Security and Vulnerability Analysis (CFSVA), Emergency Food Security
                    Assessment (EFSA); and (ii) mobile Vulnerability Analysis and Mapping (mVAM) surveys<br/>
                    <b>Updated:</b> mVAM surveys: daily or monthly; face-to-face surveys: biannually, annually or when
                    available (varies from country to country)
                </p>
            </div>
        </div>}
        {activeOverlay === 'nutritionSection' && <div className={classNames('nutritionSection', 'infoOverlay')}>
            {header}
            <div className='content'>
                <h1>Acute malnutrition</h1>
                <p>
                    Also known as ‘wasting’, acute malnutrition is characterized by a rapid deterioration in nutritional
                    status over a short period of time. In children, it can be measured using the weight-for-height
                    nutritional index or mid-upper arm circumference. There are different levels of severity of acute
                    malnutrition: moderate acute malnutrition (MAM) and severe acute malnutrition (SAM).
                </p>
                <p>
                    <b>Severe Acute Malnutrition (SAM):</b> Also known as severe wasting, SAM is defined by a very low
                    weight for height (below -3z scores of the median WHO child growth standards), or by a mid-upper
                    arm circumference (MUAC) less than 115 mm or by visible signs of severe wasting, or by the
                    presence of nutritional oedema.
                </p>
                <p>
                    <b>Moderate Acute Malnutrition:</b> Also known as moderate wasting, MAM is defined by a weight for
                    height between -3 and -2 z-scores of the median WHO child growth standards or by a mid-upper arm
                    circumference (MUAC) between 115 mm and 125 mm.
                </p>
                <p>
                    <b>Data source:</b> {info.nutritionSource !== null && info.nutritionSource}
                </p>
                <p>
                    <b>Updated:</b> Annually or when available
                </p>
                <h1>Chronic malnutrition</h1>
                <p>
                    Also known as ‘stunting’, chronic malnutrition is a form of growth failure which develops over a
                    long period of time, as a result of inadequate nutrition over long periods of time (including poor
                    maternal nutrition and poor infant and young child feeding practices) and/or repeated infections.
                    It is defined as the percentage of children, aged 0 to 59 months, who have low height for age.
                    Height for age &lt; -2 standard deviations from the median height for age of reference population =
                    Stunting. Height for age &lt; -3 standard deviations from the median height for age of reference
                    population = Severe stunting.
                </p>
                <p>
                    <b>Data source:</b> {info.nutritionSource !== null && info.nutritionSource}
                </p>
                <p>
                    <b>Updated:</b> Annually or when available
                </p>
            </div>
        </div>}
        {activeOverlay === 'foodSecurityTrendsSection' && <div className={classNames('foodSecurityTrendsSection',
                'infoOverlay')}>
            {header}
            <div className='content'>
                <h1>Food Consumption Score (FCS)</h1>
                <p>
                    is a proxy of household's food access and a core WFP indicator used to classify households into
                    different groups based on the adequacy of the foods consumed in the week prior to being surveyed.
                    FCS is the most commonly used food security indicator by WFP and partners. This indicator is a
                    composite score based on household's dietary diversity, food frequency, and relative nutritional
                    importance of different food groups.. The FCS is calculated using the frequency of consumption of eight
                    food groups by a household during the 7 days before the survey using standardized weights for each
                    of the food groups reflecting its respective nutrient density, and then classifies households as
                    having ‘poor’, ‘borderline’ or ‘acceptable’ food consumption.
                </p>
                <p>
                    <b>Poor food consumption:</b> Typically refers to households that are not consuming staples and
                    vegetables every day and never or very seldom consume protein-rich food such as meat and dairy (FCS
                    of less than 28).
                </p>
                <p>
                    <b>Borderline food consumption:</b> Typically refers to households that are consuming staples and
                    vegetables every day, accompanied by oil and pulses a few times a week (FCS of less than 42).
                </p>
                <p>
                    <b>Acceptable food consumption:</b> Typically refers to households that are consuming staples and
                    vegetables every day, frequently accompanied by oil and pulses, and occasionally meat, fish and
                    dairy (FCS greater than 42).
                </p>
                <p>
                    People with insufficient food consumption refers to those with poor or borderline food consumption.
                </p>
                <p>
                    <b>Data source:</b> World Food Programme - (i) representative face-to-face household surveys, for
                    example Comprehensive Food Security and Vulnerability Analysis (CFSVA), Emergency Food Security
                    Assessment (EFSA); and (ii) mobile Vulnerability Analysis and Mapping (mVAM) surveys
                </p>
                <p>
                    <b>Updated:</b> mVAM surveys: daily or monthly; face-to-face surveys: biannually, annually or when
                    available (varies from country to country)
                </p>
                <h1>Reduced Coping Strategies Index (rCSI)</h1>
                <p>
                    The rCSI measures the frequency and severity of the behaviours households engage in when faced with
                    shortages of food or financial resources to buy food. It assesses whether there has been a change in
                    the consumption patterns of a given household. The rCSI is calculated using standard food
                    consumption-based strategies and severity weighting. A higher score indicates that households are
                    employing more frequent and/or extreme negative coping strategies.
                </p>
                <p>
                    <b>Data source:</b> World Food Programme - (i) representative face-to-face household surveys, for
                    example Comprehensive Food Security and Vulnerability Analysis (CFSVA), Emergency Food Security
                    Assessment (EFSA); and (ii) mobile Vulnerability Analysis and Mapping (mVAM) surveys
                </p>
                <p>
                    <b>Updated:</b> mVAM surveys: daily or monthly; face-to-face surveys: biannually, annually or when
                    available (varies from country to country)
                </p>
                <h1>Estimated number of people</h1>
                <p>
                    The number of people are estimated by multiplying the percentages calculated from the mVAM data by
                    the population of the country.
                </p>
                <p>
                    <b>Data source:</b> World Food Programme - (i) representative face-to-face household surveys, for
                    example Comprehensive Food Security and Vulnerability Analysis (CFSVA), Emergency Food Security
                    Assessment (EFSA); and (ii) mobile Vulnerability Analysis and Mapping (mVAM) surveys
                </p>
                <p>
                    <b>Updated:</b> mVAM surveys: daily or monthly; face-to-face surveys: biannually, annually or when
                    available (varies from country to country)
                </p>
            </div>
        </div>}
        {activeOverlay === 'macroEconomicSection' && <div className={classNames('macroEconomicSection', 'infoOverlay')}>
            {header}
            <div className='content'>
                <h1>Import dependency</h1>
                <p>
                    Percentage of a country’s imported food for domestic supply versus its own food production for
                    domestic supply; it is calculated as follows: IDR = Imports/(local production + imports –
                    exports)*100%.
                </p>
                <p>
                    <b>Data source:</b> WFP’s calculation based on USDA data
                </p>
                <p>
                    <b>Updated:</b> Yearly
                </p>
                <h1>Currency exchange</h1>
                <p>
                    Price of a unit of domestic currency in terms of USD.
                </p>
                <p>
                    <b>Data source:</b> {info.currencyExchangeSource !== null && info.currencyExchangeSource}
                </p>
                <p>
                    <b>Updated:</b> {info.currencyExchangeUpdated !== null && info.currencyExchangeUpdated}
                </p>
                <h1>Balance of trade</h1>
                <p>
                    The balance of trade is the value of exports of goods and services less imports of goods and
                    services. It is usually the largest component of the current account.
                </p>
                <p>
                    <b>Data source:</b> Trading Economics
                </p>
                <p>
                    <b>Updated:</b> Monthly, Quarterly or Yearly (varies from country to country)
                </p>
                <h1>Headline inflation</h1>
                <p>
                    Year on year percentage change in the price of a standard basket of goods and services as calculated
                    from the national Consumer Price Index.
                </p>
                <p>
                    <b>Data source:</b> Trading Economics
                </p>
                <p>
                    <b>Updated:</b> Monthly
                </p>
                <h1>Food inflation</h1>
                <p>
                    Year-on-year percentage change in the price of a standard basket of food as calculated from the
                    national Consumer Price Index.
                </p>
                <p>
                    <b>Data source:</b> Trading Economics
                </p>
                <p>
                    <b>Updated:</b> Monthly
                </p>
            </div>
        </div>}
        {activeOverlay === 'newsSection' && <div className={classNames('newsSection', 'infoOverlay')}>
            {header}
            <div className='content'>
                <h1>News feed</h1>
                <p>
                    News articles retrieved from news sources all over the web. Topics include recent events or
                    developments relating to hazards and conflict. The news feed is country-specific and serves to
                    provide further context to the food security situation in a country.
                </p>
                <p>
                    <b>Data source:</b> News API (<a href='https://newsapi.org' target='_blank'
                                                     rel='noopener noreferrer'>newsapi.org</a>)
                </p>
                <p>
                    <b>Updated:</b> Live
                </p>
            </div>
        </div>}
        {activeOverlay === 'productsAccessSection' && <div className={classNames('productsAccessSection',
            'infoOverlay')}>
            {header}
            <div className='content'>
                <p><b>Insights and Key Trends </b></p>
                <p>
                    The HungerMapLIVE Insights and Key Trends are reports that automatically analyse each day’s data to provide an overview
                    of food security as it is now globally, regionally, and nationally.
                    <br/><br/>
                    With a new report produced daily, the Insights and Key Trends aim to signal areas that are currently at risk or deteriorating
                    across a range of indicators such as food consumption levels, market access and coping strategies.
                    Additionally, through the final page of each report, you can also access links to deep dive into the drivers of
                    hunger and analyse the impact of shocks such as conflict or COVID-19 on food security, markets and/or education.
                    <br/><br/>
                    Please note that Country-level insights are only produced for countries where WFP’s near real-time food
                    security monitoring systems are operating.
                </p>
                <p><b>Hunger and COVID-19 Snapshots</b></p>
                <p>
                    The Hunger and COVID-19 Snapshots report on countries where WFP's near real-time food security monitoring systems are in place,
                    aiming to provide a weekly overview (at national and urban levels) of the food security situation, COVID-19 caseloads, and an
                    indication of how relevant indicators – such as health and market access – are shifting.
                </p>
                <p><b>Global Hunger and COVID-19 Daily Snapshot</b></p>
                <p>
                    Every day, the HungerMapLIVE automatically analyses the latest hunger and COVID-19 data to produce a global snapshot.
                    This 1-page resource allows users to track COVID-19 cases by country income groups or regions or identify where cases are
                    rising the fastest. Additionally, this daily resource highlights the latest aggregated data on key metrics relating to
                    food security (consumption, coping strategies, market and health access) in countries where WFP's near real-time food security
                    monitoring systems are operating.
                </p>
            </div>
        </div>
        }
    </>;
};

InfoOverlays.propTypes = {
    activeLayers: PropTypes.arrayOf(PropTypes.string),
    activeOverlay: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    countryPage: PropTypes.bool.isRequired,
    info: PropTypes.object.isRequired
};
InfoOverlays.defaultProps = {
    onClose: () => {},
    countryPage: false
};

export default InfoOverlays;