import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from 'highcharts-react-official';
import {useWindowHeight} from '@react-hook/window-size';
import constants from './constants';
import moment from "moment";

HighchartsMore(Highcharts);

const numberFormat = new Intl.NumberFormat('en-US');

const BarChart = ({series}) => {
    const windowHeight = useWindowHeight();
    const tallScreen = windowHeight > constants.tallScreenMinHeight;
    const smallScreen = windowHeight <= constants.mediumScreenMinHeight;

    const chartSeries = [{
        data: series.data,
        color: series.color,
        marker: {enabled: false},
        type: 'column',
        states: {hover: {enabled: false}}
    }];
    const height = tallScreen ? 120 : (smallScreen ? 90 : 110);
    const fontSize = tallScreen ? '7pt' : (smallScreen ? '5pt' : '6pt');

    const values = series.data.map(({y}) => y);
    const maxY = Math.max(...values);
    const minY = Math.min(...values);

    let ticks;
    if (minY === maxY) {
        ticks = [0, maxY];
    } else {
        ticks = [
            0,
            Math.round(maxY / 3),
            Math.round(maxY / 3 * 2),
            maxY
        ];
        ticks.sort((a, b) => a - b);
    }

    return <HighchartsReact
        highcharts={Highcharts}
        options={{
            title: {text: undefined},
            series: chartSeries,
            chart: {
                backgroundColor: 'transparent',
                height: height,
                width: tallScreen ? 150 : 120,
                margin: [15, undefined, 20, undefined],
                type: 'column'
            },
            credits: {
                enabled: false
            },
            yAxis: {
                lineColor: '#fff',
                lineWidth: 1,
                title: {
                    text: '',
                    rotation: 0,
                    style: {
                        color: '#fff',
                        fontSize: fontSize
                    },
                    margin: 0,
                    y: - (height / 3) - 5,
                    x: 10
                },
                gridLineWidth: 1,
                gridLineDashStyle: 'dot',
                gridLineColor: 'rgba(255, 255, 255, 0.1)',
                labels: {
                    enabled: true,
                    style: {
                        color: '#fff',
                        fontSize: fontSize,
                        width: 'auto',
                    },
                    x: -5,
                    useHTML: true,
                    formatter: function() {
                        return numberFormat.format(this.value)
                    },
                },
                tickPositions: ticks
            },
            xAxis: {
                tickWidth: 0,
                labels: {
                    enabled: true,
                    style: {
                        color: '#fff',
                        fontSize: fontSize,
                        width: 'auto',
                    },
                    useHTML: true,
                    overflow: 'allow',
                    x: -10,
                    formatter: function () {
                        return moment(this.value).format('YYYY-MM-DD');
                    },
                },
                type: 'datetime',
                tickPositions: [series.data[series.data.length - 1].x.getTime()]
            },
            plotOptions: {
                series: {
                    events: {legendItemClick: function () {return false;}},
                    states: {hover: {enabled: false}, inactive: {opacity: 1}},
                    marker: {enabled: false}
                },
                column: {
                    borderWidth: 0,
                    groupPadding: 0.05
                }
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                followPointer: true,
                formatter: function() {
                    return `<b>${moment(this.x).format('YYYY-MM-DD')}</b><br/>` +
                        `<span style="color:${this.point.color}">\u25A0</span> ${this.y}`;
                },
                useHTML: true,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                borderWidth: 0,
                style: {
                    color: '#fff'
                },
            }
        }}
    />;
};

BarChart.propTypes = {
    series: PropTypes.object
};
BarChart.defaultProps = {};

export default BarChart;