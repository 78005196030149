import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './DisclaimersPopup.scss';
import { imgFile } from "./utils";

const DisclaimersPopup = ({ activeSection, onClose, onChangeContent }) => {
    const [glossaryCriteria, setGlossaryCriteria] = useState('topic');
    const [glossarySearch, setGlossarySearch] = useState('');
    useEffect(() => {
        setGlossaryCriteria('topic');
        setGlossarySearch('');
    }, [activeSection]);

    const renderGlossaryContent = contentList => contentList.map(c => (<>
        <tr>
            <td className='firstColumn'><b>{c.term}</b><br />{c.definition}</td>
            <td className='secondColumn'>Source:</td>
            <td className='thirdColumn'>{c.dataSource}</td>
        </tr>
        <tr className='contentBottom'>
            <td className='firstColumn'> </td>
            <td className='secondColumn'>Updated:</td>
            <td className='thirdColumn'>{c.updateFrequency}</td>
        </tr>
    </>));

    const glossaryItems = [{
        header: 'Context and Need',
        content: [{
            term: 'Conflict',
            definition: <>All reported violence and conflicts across Africa, the Middle East, South and South East Asia, Eastern and Southeastern Europe and the Balkans.</>,
            dataSource: 'Armed Conflict Location & Event Data Project (ACLED) - acleddata.com',
            updateFrequency: 'Daily, displaying data from the last 30 days'
        }, {
            term: 'Hazards',
            definition: <>Current hazards information: Active Volcanoes; Active/Forecast Wind Radii (39, 58, 74); Previous, Current and Forecast Storm Positions; 3- and 5-day Potential Track Area of Storms; Recent Earthquakes; MODIS Hotspots; Tsunami Travel Time; GLIDE Events; H1N1 Affected Countries; Country Boundaries and Labels; Global Shaded Relief; Global Population Density; and PDC integrated hazards.</>,
            dataSource: 'Pacific Disaster Centre (PDC) - Active Hazards Map Service',
            updateFrequency: 'Real-time (every hour)'
        }, {
            term: 'Population',
            definition: <>Total population counts all residents, regardless of legal status or citizenship (the values shown are mid-year estimates).</>,
            dataSource: 'World Bank',
            updateFrequency: 'N/A'
        }]
    }, {
        header: 'Food Security',
        content: [{
            term: 'Estimated number of people',
            definition: <>The number of people are estimated by multiplying the percentages calculated from WFP mVAM data by the population of the country.</>,
            dataSource: 'World Food Programme - (i) representative face-to-face household surveys, for example Comprehensive Food Security and Vulnerability Analysis (CFSVA), Emergency Food Security Assessment (EFSA); and (ii) mobile Vulnerability Analysis and Mapping (mVAM) surveys',
            updateFrequency: 'mVAM surveys: daily or monthly; face-to-face surveys: biannually, annually or when available (varies from country to country)'
        }, {
            term: 'People with insufficient food consumption',
            definition: <>People with insufficient food consumption refer to those with poor or borderline food consumption, according to the <b>Food Consumption Score (FCS)</b>. The Food Consumption Score (FCS) is a proxy of household's food access and a core WFP indicator used to classify households into different groups based on the adequacy of the foods consumed in the week prior to being surveyed. FCS is the most commonly used food security indicator by WFP and partners. This indicator is a composite score based on households’ dietary diversity, food frequency, and relative nutritional importance of different food groups. The FCS is calculated using the frequency of consumption of eight food groups by a household during the 7 days before the survey using standardized weights for each of the food groups reflecting its respective nutrient density, and then classifies households as having ‘poor’, ‘borderline’ or ‘acceptable’ food consumption: <b>Poor food consumption:</b> Typically refers to households that are not consuming staples and vegetables every day and never or very seldom consume protein-rich food such as meat and dairy (FCS of less than 21 or 28). <b>Borderline food consumption:</b> Typically refers to households that are consuming staples and vegetables every day, accompanied by oil and pulses a few times a week (FCS of less than 35 or 42). <b>Acceptable food consumption: </b>Typically refers to households that are consuming staples and vegetables every day, frequently accompanied by oil and pulses, and occasionally meat, fish and dairy (FCS greater than 42).</>,
            dataSource: 'World Food Programme - (i) representative face-to-face household surveys, for example Comprehensive Food Security and Vulnerability Analysis (CFSVA), Emergency Food Security Assessment (EFSA); and (ii) mobile Vulnerability Analysis and Mapping (mVAM) surveys',
            updateFrequency: 'mVAM surveys: daily or monthly; face-to-face surveys: biannually, annually or when available (varies from country to country)'
        }, {
            term: 'Integrated Food Security Phase Classification (IPC) / Cadre Harmonisé (CH)',
            definition: <>Developed by a global partnership, the IPC/CH is a set of tools and procedures to classify food insecurity. It classifies the populations in five different phases according to the severity of the food insecurity and malnutrition situation: Minimal, Stressed, Crisis, Emergency, and Catastrophe/Famine.</>,
            dataSource: 'Integrated Phase Classification (IPC) / Cadre Harmonisé (CH) http://www.ipcinfo.org ',
            updateFrequency: 'N/A '
        }, {
            term: 'reduced Coping Strategies Index (rCSI)',
            definition: <>The reduced Coping Strategies Index (rCSI) measures the frequency and severity of the behaviours households engage in when faced with shortages of food or financial resources to buy food. It assesses whether there has been a change in the consumption patterns of a given household. The rCSI is calculated using standard food consumption-based strategies and severity weighting. A higher score indicates that households are employing more frequent and/or extreme negative coping strategies.</>,
            dataSource: 'World Food Programme - (i) representative face-to-face household surveys, for example Comprehensive Food Security and Vulnerability Analysis (CFSVA), Emergency Food Security Assessment (EFSA); and (ii) mobile Vulnerability Analysis and Mapping (mVAM) surveys',
            updateFrequency: 'mVAM surveys: daily or monthly; face-to-face surveys: biannually, annually or when available (varies from country to country)'
        }, {
            term: 'Undernourishment',
            definition: <>Undernourishment is defined as the condition in which an individual’s habitual food consumption is insufficient to provide the amount of dietary energy required to maintain a normal, active, healthy life. The indicator is reported as the prevalence of undernourishment (PoU), which is an estimate of the percentage of individuals in the total population that are in a condition of undernourishment. To reduce the influence of possible estimation errors in some of the underlying parameters, national estimates are reported as a three-year moving average. </>,
            dataSource: 'Source: FAO, IFAD, UNICEF, WFP and WHO. 2019. The State of Food Security and Nutrition in the World 2019. Safeguarding against economic slowdowns and downturns. Further information is available at https://www.wfp.org/publications/2019-state-food-security-and-nutrition-world-sofi-safeguarding-against-economic',
            updateFrequency: 'Annually'
        }]
    }, {
        header: 'Nutrition',
        content: [{
            term: 'Acute malnutrition',
            definition: <>Also known as ‘wasting’, acute malnutrition is characterized by a rapid deterioration in nutritional status over a short period of time. In children, it can be measured using the weight-for-height nutritional index or mid-upper arm circumference. There are different levels of severity of acute malnutrition: moderate acute malnutrition (MAM) and severe acute malnutrition (SAM).Severe Acute Malnutrition (SAM): Also known as severe wasting, SAM is defined by a very low weight for height (below -3z scores of the median WHO child growth standards), or by a mid-upper arm circumference (MUAC) less than 115 mm or by visible signs of severe wasting, or by the presence of nutritional oedema. Moderate Acute Malnutrition (MAM): Also known as moderate wasting, MAM is defined by a weight for height between -3 and -2 z-scores of the median WHO child growth standards or by a mid-upper arm circumference (MUAC) between 115 mm and 125 mm.</>,
            dataSource: 'Joint Malnutrition Estimates – UNICEF, WHO, World Bank',
            updateFrequency: 'Annually or when available'
        }, {
            term: 'Chronic malnutrition',
            definition: <>Also known as ‘stunting’, chronic malnutrition is a form of growth failure which develops over a long period of time, as a result of inadequate nutrition over long periods of time (including poor maternal nutrition and poor infant and young child feeding practices) and/or repeated infections. It is defined as the percentage of children, aged 0 to 59 months, who have low height for age. Height for age &lt; -2 standard deviations from the median height for age of reference population = Stunting. Height for age &lt; -3 standard deviations from the median height for age of reference population = Severe stunting.</>,
            dataSource: 'Joint Malnutrition Estimates – UNICEF, WHO, World Bank',
            updateFrequency: 'Annually or when available'
        }]
    }, {
        header: 'Markets',
        content: [{
            term: 'Import dependency',
            definition: <>Percentage of a country’s imported food for domestic supply versus its own food production for domestic supply; it is calculated as follows: IDR = Imports/(local production + imports – exports)*100%.</>,
            dataSource: 'WFP’s calculation based on USDA data',
            updateFrequency: 'Daily'
        }, {
            term: 'Currency exchange',
            definition: <>Price of a unit of domestic currency in terms of USD.</>,
            dataSource: 'Trading Economics',
            updateFrequency: 'Yearly'
        }, {
            term: 'Balance of trade',
            definition: <>The balance of trade is the value of exports of goods and services less imports of goods and services. It is usually the largest component of the current account.</>,
            dataSource: 'Trading Economics',
            updateFrequency: 'Monthly, Quarterly or Yearly (varies from country to country)'
        }, {
            term: 'Food inflation',
            definition: <>Year-on-year percentage change in the price of a standard basket of food as calculated from the national Consumer Price Index.</>,
            dataSource: 'Trading Economics',
            updateFrequency: 'Monthly'
        }, {
            term: 'Headline inflation',
            definition: <>Year on year percentage change in the price of a standard basket of goods and services as calculated from the national Consumer Price Index.</>,
            dataSource: 'Trading Economics',
            updateFrequency: 'Monthly'
        }]
    }, {
        header: 'Seasonal Information',
        content: [{
            term: 'Rainfall',
            definition: <>The 1-month rainfall anomaly layer shows cumulative seasonal rainfall as a percentage of the 20-year average every ten days. Values below 100% indicate drier than average conditions, above 100% indicate wetter than average conditions. Variations between 90% and 110% are considered as having an inconsequential impact for crops or pasture and these are represented in white. Otherwise, brown shades indicate below-average rainfall and blue shades indicate above-average seasonal rainfall. Users can evaluate whether the season is becoming drier than average (blue shades turning lighter or brown shades getting darker) or wetter than average (brown shades turning lighter or blue shades getting darker).</>,
            dataSource: 'CHIRPS rainfall estimates, Climate Hazards Group, University of California at Santa Barbara; data processed by WFP VAM ',
            updateFrequency: 'Every 10 days'
        }, {
            term: 'Vegetation',
            definition: <>The Normalized Difference Vegetation Index (NDVI) layer shows the recent vegetation development compared to the average. Green shades show areas where vegetation cover is above average, whilst orange and brown shades identify areas where vegetation cover is below normal. Users can evaluate whether vegetation cover is becoming sparser (lightening greens or darkening oranges) or denser (lightening oranges and darkening greens). Values between 90% and 110% are considered as being within the range of normal variability.</>,
            dataSource: 'MODIS platforms Terra and Aqua. MODIS NDVI CMG data product retrieved from Earthdata Search, courtesy of NASA EOSDIS Land Processes Distributed Active Archive Center (LP DAAC), USGS/Earth Resources Observation and Science (EROS) Center and Sioux Falls, South Dakota, USA.',
            updateFrequency: 'Every 8 days'
        }, {
            term: 'River basins',
            definition: <>The river basins visualization provides rainfall data for the last 35+ years for five of the world’s major rivers (Limpopo, Nile, Orange, Shabelli-Juba, and Zambesi), allowing users to track whether the current basin-wide rainfall is within the normal range, or whether there is a risk of drought and lower river flows or flooding and high river flows.</>,
            dataSource: 'CHIRPS rainfall estimates, Climate Hazards Group, University of California at Santa Barbara; data processed by WFP-VAM ',
            updateFrequency: 'Available at 5 and 10 day intervals'
        }]
    }, {
        header: 'Other',
        content: [{
            term: 'News feed',
            definition: <>News articles retrieved from news sources all over the web. Topics include recent events or developments relating to hazards and conflict. The news feed is country-specific and serves to provide further context to the food security situation in a country.</>,
            dataSource: 'NewsAPI (newsapi.org)',
            updateFrequency: 'Live'
        }]
    }];

    return <div className={`disclaimerPopup ${activeSection ? 'active' : ''}`}>
        <div className='topright'>
            <img src={imgFile('close_icon.png')}
                className='link' alt='' onClick={onClose} />
        </div>
        {activeSection === 'disclaimer' && <div className='content'>
            <span className='title'>Disclaimer</span>
            <p>
                COVID-19 caseloads are aggregated by World Bank income groups: low-income, lower-middle income,
                upper-middle income, and high-income countries.<br />
                Note: Larger numbers in confirmed COVID-19 cases in higher income countries may be attributed
                to a country's testing capacity.
            </p>
            <hr />
            <p>
                The designations employed and the presentation of material for all the maps on this site do not
                imply the expression of any opinion whatsoever on the part of the World Food Programme concerning
                the legal or constitutional status of any country, territory, city or area or of its authorities,
                or concerning the delimitation of its frontiers or boundaries.
            </p>
            <ol>
                <li>Dotted line represents approximately the Line of Control in Jammu and Kashmir agreed upon
                by India and Pakistan. The final status of Jammu and Kashmir has not yet been agreed upon
                    by the parties.</li>
                <li>Final boundary between the Republic of Sudan and the Republic of South Sudan has not yet
                    been determined.</li>
                <li>Final status of the Abyei area is not yet determined.</li>
                <li>A dispute exists between the Governments of Argentina and the United Kingdom of Great
                Britain and Northern Ireland concerning sovereignty over the Falkland Islands
                    (Malvinas).</li>
            </ol>
        </div>}
        {activeSection === 'methodology' && <div className='content'>
            <span className='title'>About The Crisis Monitoring Dashboard</span>
            <p>
            IDB has partnered with the United Nation’s World Food Programme (WFP) to develop real-time food security crisis 
            triggers for countries throughout Latin America and the Caribbean.  Leveraging WFP’s global real-time monitoring systems, 
            HungerMap LIVE (HML), IDB is now able to identify and quantify emerging food crises in real-time, understand in-depth what is 
            driving these food crises and be able to engage with Governments throughout the region to mitigate the impacts of these events.  
            This technical note provides a detailed methodology as to how this is done, what sources of information are utilized and the scope 
            of these activities now and into the future. 
            </p>
            <p>
                For any questions, comments, or if you would like further information, please get in touch by sending
                an email to <a href={'mailto:wfp.mvam@wfp.org'}>wfp.mvam@wfp.org</a>
            </p>

            <span className='title'>Methodology</span>
            <p>
            IDB's Food Security Crisis Triggering System operates in real time and is fully automated.  Food security is updated daily, with'
             information flowing from WFP’s HungerMap LIVE. IPC information is updated automatically when new IPC analyses are completed, while 
             undernourishment and FIES data are updated automatically on an annual basis. Information on the drivers of food insecurity are updated 
             on a periodic basis, as defined below: 
            <ul>
                <li>Conflict/ social unrest data- updated adhoc, as data is compiled by ACLED </li>
                <li>Climate and weather data- updated every 10 days (decadal information)</li>
                <li>Food inflation- updated monthly </li>
                <li>Hazard information- updated on an adhoc basis </li>
            </ul>   
            Analytics are fully automated and analysis pipeline run nightly.  Information is stored in a SQL database, which over time will be fully 
            transition from WFP to IDB systems. Reporting is automated as well with weekly report automatically disbursed to IDB staff.
 
            </p>
        </div>}
        {activeSection === 'glossary' && <div className='content'>
            <div className='glossaryControls'>Order by:
                <span className={`orderCriteria ${glossaryCriteria === 'az' ? 'active' : ''}`}
                    onClick={() => { setGlossarySearch(''); setGlossaryCriteria('az') }}>A-Z</span>
                <span className={`orderCriteria ${glossaryCriteria === 'topic' ? 'active' : ''}`}
                    onClick={() => { setGlossarySearch(''); setGlossaryCriteria('topic') }}>TOPIC</span>
                <input placeholder='Search in Glossary' value={glossarySearch}
                    onChange={(e) => {
                        setGlossarySearch(e.target.value);
                        if (e.target.value !== '') setGlossaryCriteria('search');
                        else setGlossaryCriteria('topic');
                    }} />
            </div>
            <span className='title'>Glossary</span>
            <p>
                This section includes all indicators and data sources displayed on Hunger Map LIVE (global and
                country pages). Beyond the data sources credited hereunder, additional sources used as input
                variables for the predictive model but not for display purposes are listed in the Methodology
                section.
            </p>
            <table className='glossary'>
                <tbody>
                    {glossaryCriteria === 'topic' && glossaryItems.map(item => (<>
                        <tr className='header'>
                            <td className='firstColumn'>{item.header}</td>
                            <td className='secondColumn'> </td>
                            <td className='thirdColumn'> </td>
                        </tr>
                        {renderGlossaryContent(item.content)}
                    </>))}
                    {glossaryCriteria === 'az' && renderGlossaryContent(
                        [].concat.apply([], glossaryItems.map(({ content }) => content))
                            .sort((a, b) => (a.term.toLowerCase() < b.term.toLowerCase() ? -1 : 1)))}
                    {glossaryCriteria === 'search' && glossaryItems.map(item => (item.content.filter(
                        ({ term }) => term.toLowerCase().indexOf(glossarySearch.toLowerCase()) > -1
                    ).length > 0 && <>
                            <tr className='header'>
                                <td className='firstColumn'>{item.header}</td>
                                <td className='secondColumn'> </td>
                                <td className='thirdColumn'> </td>
                            </tr>
                            {renderGlossaryContent(item.content.filter(
                                ({ term }) => term.toLowerCase().indexOf(glossarySearch.toLowerCase()) > -1
                            ))}
                        </>))}
                </tbody>
            </table>
        </div>}
        {activeSection === 'updates' && <div className='content'>
            <p>Thank you for visiting the HungerMap<span className='live'>LIVE</span>, WFP’s global hunger monitoring system. </p>
            <p>Before you continue, please note that a new and improved predictive model has now been applied to the HungerMapLIVE.
                This new model is an upgrade of the HungerMap<span className='live'>LIVE</span> ’s existing predictive analytics, which is used to estimate the number
                of people with insufficient food consumption in areas where daily, updated survey data is not available.  </p>
            <p>What’s new?</p>
            <div>
                <ul>
                    <li>A much broader set of data has been used to train this new model. Access to more training data was made possible by the rapid expansion of WFP’s
                        near real-time monitoring systems, which increased from monitoring 15 countries to 35 over the past year.
                    </li>
                    <li>
                        The methodology has been improved in several ways:
                        <ul>
                            <li>
                                The scope of data used has been widened - increasing the number of countries covered (now over 75) and the number of years considered.
                            </li>
                            <li>
                                The input variables used to inform the model’s calculations have been refined.
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <p>
                As a result of this upgrade, the predictive model’s performance has significantly increased, thereby providing more precise estimates of the number
                of people with insufficient food consumption. You can access relevant metrics from our tests by visiting the Model Evaluation section of the
                HungerMap<span className='live'>LIVE</span> ’s Methodology page.
            </p>
            <p>
                Additionally, if you accessed the HungerMap<span className='live'>LIVE</span> and its related products before 21-Apr-2021 please note that figures
                and trends would have changed since then. These changes are a result of increased precision and refinements in the predictive model.
            </p>
        </div>}
    </div>
};

      

DisclaimersPopup.propTypes = {
    activeSection: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onChangeContent: PropTypes.func.isRequired,
};
DisclaimersPopup.defaultProps = {
    onClose: () => { },
    onChangeContent: () => { }
};
export default DisclaimersPopup;
